<template>
  <div id="app">
    <h1>Inventario de Productos</h1>
    <ag-grid-vue
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowData="rowData"
      :domLayout="'autoHeight'"
      :pagination="true"
      :paginationPageSize="10"
      :filter="true"
      :enableSorting="true"
      :enableFilter="true"
      style="width: 100%; height: 500px"
    ></ag-grid-vue>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-community/styles/ag-grid.css";
import { ModuleRegistry, ClientSideRowModelModule } from "ag-grid-community";

ModuleRegistry.registerModules([ClientSideRowModelModule]);
import "ag-grid-community/styles/ag-theme-alpine.css";

export default {
  name: "App",
  components: {
    AgGridVue,
  },
  data() {
    return {
      // Definir las columnas de la tabla con filtros
      columnDefs: [
        {
          headerName: "Producto",
          field: "producto",
          filter: "agTextColumnFilter", // Filtro de texto
          sortable: true,
        },
        {
          headerName: "Categoría",
          field: "categoria",
          filter: "agSetColumnFilter", // Filtro de selección de categorías
          sortable: true,
        },
        {
          headerName: "Sucursal",
          field: "sucursal",
          filter: "agSetColumnFilter", // Filtro de sucursales
          sortable: true,
        },
        {
          headerName: "Existencias",
          field: "existencias",
          filter: "agNumberColumnFilter", // Filtro numérico
          sortable: true,
        },
        {
          headerName: "Precio",
          field: "precio",
          filter: "agNumberColumnFilter", // Filtro numérico
          sortable: true,
        },
        {
          headerName: "Fecha de Entrada",
          field: "fechaEntrada",
          filter: "agDateColumnFilter", // Filtro de fecha
          sortable: true,
        },
      ],
      // Datos de ejemplo para los productos
      rowData: [
        {
          producto: "Laptop",
          categoria: "Electrónica",
          sucursal: "Sucursal A",
          existencias: 30,
          precio: 800,
          fechaEntrada: "2024-12-01",
        },
        {
          producto: "Smartphone",
          categoria: "Electrónica",
          sucursal: "Sucursal B",
          existencias: 50,
          precio: 600,
          fechaEntrada: "2024-11-15",
        },
        {
          producto: "Teclado",
          categoria: "Accesorios",
          sucursal: "Sucursal A",
          existencias: 100,
          precio: 50,
          fechaEntrada: "2024-12-20",
        },
        {
          producto: "Monitor",
          categoria: "Electrónica",
          sucursal: "Sucursal C",
          existencias: 20,
          precio: 200,
          fechaEntrada: "2024-10-10",
        },
        {
          producto: "Mouse",
          categoria: "Accesorios",
          sucursal: "Sucursal B",
          existencias: 80,
          precio: 30,
          fechaEntrada: "2024-09-25",
        },
      ],
    };
  },
};
</script>

<style scoped>
/* Puedes personalizar la apariencia aquí */
</style>
