<template>
  <v-container v-if="user">
    <v-card>
      <v-card-title class="text-center teal lighten-3 accent-4 white--text">
        Avance
        <v-spacer></v-spacer>
        <!-- <v-btn @click="verData" class="mr-2" color="primary">data</v-btn> -->
      </v-card-title>
      <v-row class="mx-2 mt-2">
        <v-col cols="12" md="12">
          <h4>Sucursales:</h4>
          <!-- 
          <v-select
            v-model="selectedSucursales"
            :items="sucursales"
            item-value="value"
            label="Sucursales"
            placeholder="Selecciona sucursales"
            multiple
            chips
            default-chips
          ></v-select>
          -->
        </v-col>
        <!-- MUESTRA EL MENU DE FORMA DE CHECKBOX SIN NECESIDAD DE ABRIR EL MENU DESPLEGABLE -->
        <v-checkbox
          v-for="(sucursal, index) in sucursales"
          v-model="selectedSucursales"
          :key="index"
          :label="sucursal.text"
          :value="sucursal.value"
          class="mr-7 ml-5"
        >
        </v-checkbox>
        <!-- FIN DE CHECKBOX -->
        <v-col cols="12" md="12">
          <v-menu
            v-model="menuMes"
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="selectedMes"
                label="Mes"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="selectedMes"
              type="month"
              no-title
              scrollable
              locale="es"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2">
          <v-btn @click="getReporte" small class="mt-5" :loading="loadingBtn"
            >Buscar</v-btn
          >
        </v-col>
      </v-row>
      <!-- Mostrar los totales de todas las sucursales y el porcentaje de dias habiles transcurridos del mes -->
      <v-row
        v-if="dataAvances.length > 0 && dataTotalSucursales.length > 0"
        justify="center"
        class="mx-0 mt-0"
      >
        <v-col cols="12">
          <div class="text-center pt-2">
            <span class="font-weight-bold text-subtitle-1"
              >Porcentaje de días hábiles transcurridos del mes:
            </span>
            {{ porcentajeDiasHabilesTranscurridos + "%" }}
          </div>
        </v-col>
        <v-col cols="12" md="12">
          <v-card>
            <v-card-text>
              <v-data-table
                :headers="headersTotalSucursales"
                :items="dataTotalSucursales"
                :items-per-page="-1"
                hide-default-footer
                no-data-text="No hay resultados"
              >
                <!-- Se agrega un encabezado con el nombre de la sucursal -->
                <template v-slot:top>
                  <div class="font-weight-bold text-subtitle-1 text-center">
                    Total de sucursales
                  </div>
                </template>
                <template v-slot:[`item.neto-desc`]="{ item }">
                  {{ formatoPrecio(item[`neto-desc`]) }}
                </template>
                <template v-slot:[`item.velocidad`]="{ item }">
                  {{ formatoPrecio(item.velocidad) }}
                </template>
                <template v-slot:[`item.cuota`]="{ item }">
                  {{ formatoPrecio(item.cuota) }}
                </template>
                <template v-slot:[`item.porcentaje`]="{ item }">
                  {{ item.porcentaje + "%" }}
                  <span
                    :style="{ color: item.diferencia >= 0 ? 'green' : 'red' }"
                  >
                    ({{ item.diferencia + "%" }})
                  </span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Tabla sucursal Mexicali -->
      <v-row
        v-if="
          dataAvances.length > 0 && dataTotalMexicali.totalAgentes.length > 0
        "
        justify="center"
        class="mx-0 mt-0"
      >
        <v-col cols="12" md="12">
          <v-card class="pa-0">
            <v-card-text>
              <v-data-table
                v-if="dataTotalMexicali.totalAgentes.length > 0"
                :headers="headers"
                :items="dataTotalMexicali.totalAgentes"
                :items-per-page="-1"
                hide-default-footer
                no-data-text="No hay resultados"
              >
                <!-- Se agrega un encabezado con el nombre de la sucursal -->
                <template v-slot:top>
                  <div class="font-weight-bold body-1 text-center">
                    Sucursal Mexicali
                  </div>
                  <div class="pt-2 text-center">
                    <span class="font-weight-bold text-subtitle-1"
                      >Avance del mes:
                    </span>
                    {{ porcentajeDiasHabilesTranscurridos + "%" }}
                  </div>
                </template>
                <!-- Se agrega una fila al final de la tabla con los totales -->
                <template v-slot:[`body.append`]>
                  <tr>
                    <td colspan="5" class="font-weight-bold body-1 text-center">
                      <span class="text-center"
                        >Total Sucursal de Mexicali:</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="text-center">
                      <span class="font-weight-bold body-1 text-center"
                        >Neto-desc:</span
                      >
                      {{
                        formatoPrecio(
                          dataTotalMexicali.totalDeSucursal["neto-desc"]
                        )
                      }}
                    </td>
                    <td colspan="5" class="text-center">
                      <span class="font-weight-bold body-1 text-center"
                        >Velocidad:</span
                      >
                      {{
                        formatoPrecio(
                          dataTotalMexicali.totalDeSucursal.velocidad
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="text-center">
                      <span class="font-weight-bold body-1 text-center"
                        >Cuota:</span
                      >
                      {{
                        formatoPrecio(dataTotalMexicali.totalDeSucursal.cuota)
                      }}
                    </td>
                    <td colspan="5" class="text-center">
                      <span class="font-weight-bold body-1 text-center"
                        >Porcentaje:</span
                      >

                      {{ dataTotalMexicali.totalDeSucursal.porcentaje + "%" }}

                      <span
                        :style="{
                          color:
                            dataTotalMexicali.totalDeSucursal.diferencia >= 0
                              ? 'green'
                              : 'red',
                        }"
                      >
                        ({{
                          dataTotalMexicali.totalDeSucursal.diferencia + "%"
                        }})
                      </span>
                    </td>
                  </tr>
                </template>
                <template v-slot:[`item.neto-desc`]="{ item }">
                  {{ formatoPrecio(item[`neto-desc`]) }}
                </template>
                <template v-slot:[`item.velocidad`]="{ item }">
                  {{ formatoPrecio(item.velocidad) }}
                </template>
                <template v-slot:[`item.cuota`]="{ item }">
                  {{ formatoPrecio(item.cuota) }}
                </template>
                <template v-slot:[`item.porcentaje`]="{ item }">
                  {{ item.porcentaje + "%" }}

                  <span
                    :style="{ color: item.diferencia >= 0 ? 'green' : 'red' }"
                  >
                    ({{ item.diferencia + "%" }})
                  </span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Tabla sucursal Tijuana -->
      <v-row
        v-if="
          dataAvances.length > 0 && dataTotalTijuana.totalAgentes.length > 0
        "
        justify="center"
        class="mx-0 mt-0"
      >
        <v-col cols="12" md="12">
          <v-card class="pa-0">
            <v-card-text>
              <v-data-table
                v-if="dataTotalTijuana.totalAgentes.length > 0"
                :headers="headers"
                :items="dataTotalTijuana.totalAgentes"
                :items-per-page="-1"
                hide-default-footer
                no-data-text="No hay resultados"
              >
                <!-- Se agrega un encabezado con el nombre de la sucursal -->
                <template v-slot:top>
                  <div class="font-weight-bold text-subtitle-1 text-center">
                    Sucursal Tijuana
                  </div>
                  <div class="pt-2 text-center">
                    <span class="font-weight-bold text-subtitle-1"
                      >Avance del mes:
                    </span>
                    {{ porcentajeDiasHabilesTranscurridos + "%" }}
                  </div>
                </template>
                <!-- Se agrega una fila al final de la tabla con los totales -->
                <template v-slot:[`body.append`]>
                  <tr>
                    <td colspan="5" class="font-weight-bold body-1 text-center">
                      <span class="text-center"
                        >Total Sucursal de Tijuana:</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="text-center">
                      <span class="font-weight-bold body-1 text-center"
                        >Neto-desc:</span
                      >
                      {{
                        formatoPrecio(
                          dataTotalTijuana.totalDeSucursal["neto-desc"]
                        )
                      }}
                    </td>
                    <td colspan="5" class="text-center">
                      <span class="font-weight-bold body-1 text-center"
                        >Velocidad:</span
                      >
                      {{
                        formatoPrecio(
                          dataTotalTijuana.totalDeSucursal.velocidad
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="text-center">
                      <span class="font-weight-bold body-1 text-center"
                        >Cuota:</span
                      >
                      {{
                        formatoPrecio(dataTotalTijuana.totalDeSucursal.cuota)
                      }}
                    </td>
                    <td colspan="5" class="text-center">
                      <span class="font-weight-bold body-1 text-center"
                        >Porcentaje:</span
                      >

                      {{ dataTotalTijuana.totalDeSucursal.porcentaje + "%" }}
                      <span
                        :style="{
                          color:
                            dataTotalTijuana.totalDeSucursal.diferencia >= 0
                              ? 'green'
                              : 'red',
                        }"
                      >
                        ({{
                          dataTotalTijuana.totalDeSucursal.diferencia + "%"
                        }})
                      </span>
                    </td>
                  </tr>
                </template>
                <template v-slot:[`item.neto-desc`]="{ item }">
                  {{ formatoPrecio(item[`neto-desc`]) }}
                </template>
                <template v-slot:[`item.velocidad`]="{ item }">
                  {{ formatoPrecio(item.velocidad) }}
                </template>
                <template v-slot:[`item.cuota`]="{ item }">
                  {{ formatoPrecio(item.cuota) }}
                </template>
                <template v-slot:[`item.porcentaje`]="{ item }">
                  {{ item.porcentaje + "%" }}
                  <span
                    :style="{ color: item.diferencia >= 0 ? 'green' : 'red' }"
                  >
                    ({{ item.diferencia + "%" }})
                  </span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Tabla sucursal Ensenada -->
      <v-row
        v-if="
          dataAvances.length > 0 && dataTotalEnsenada.totalAgentes.length > 0
        "
        justify="center"
        class="mx-0 mt-0"
      >
        <v-col cols="12" md="12">
          <v-card class="pa-0">
            <v-card-text>
              <v-data-table
                v-if="dataTotalEnsenada.totalAgentes.length > 0"
                :headers="headers"
                :items="dataTotalEnsenada.totalAgentes"
                :items-per-page="-1"
                hide-default-footer
                no-data-text="No hay resultados"
              >
                <!-- Se agrega un encabezado con el nombre de la sucursal -->
                <template v-slot:top>
                  <div class="font-weight-bold text-subtitle-1 text-center">
                    Sucursal Ensenada
                  </div>
                  <div class="pt-2 text-center">
                    <span class="font-weight-bold text-subtitle-1"
                      >Avance del mes:
                    </span>
                    {{ porcentajeDiasHabilesTranscurridos + "%" }}
                  </div>
                </template>
                <!-- Se agrega una fila al final de la tabla con los totales -->
                <template v-slot:[`body.append`]>
                  <tr>
                    <td colspan="5" class="font-weight-bold body-1 text-center">
                      <span class="text-center"
                        >Total Sucursal de Ensenada:</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="text-center">
                      <span class="font-weight-bold body-1 text-center"
                        >Neto-desc:</span
                      >
                      {{
                        formatoPrecio(
                          dataTotalEnsenada.totalDeSucursal["neto-desc"]
                        )
                      }}
                    </td>
                    <td colspan="5" class="text-center">
                      <span class="font-weight-bold body-1 text-center"
                        >Velocidad:</span
                      >
                      {{
                        formatoPrecio(
                          dataTotalEnsenada.totalDeSucursal.velocidad
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="text-center">
                      <span class="font-weight-bold body-1 text-center"
                        >Cuota:</span
                      >
                      {{
                        formatoPrecio(dataTotalEnsenada.totalDeSucursal.cuota)
                      }}
                    </td>
                    <td colspan="5" class="text-center">
                      <span class="font-weight-bold body-1 text-center"
                        >Porcentaje:</span
                      >

                      {{ dataTotalEnsenada.totalDeSucursal.porcentaje + "%" }}
                      <span
                        :style="{
                          color:
                            dataTotalEnsenada.totalDeSucursal.diferencia >= 0
                              ? 'green'
                              : 'red',
                        }"
                      >
                        ({{
                          dataTotalEnsenada.totalDeSucursal.diferencia + "%"
                        }})
                      </span>
                    </td>
                  </tr>
                </template>
                <template v-slot:[`item.neto-desc`]="{ item }">
                  {{ formatoPrecio(item[`neto-desc`]) }}
                </template>
                <template v-slot:[`item.velocidad`]="{ item }">
                  {{ formatoPrecio(item.velocidad) }}
                </template>
                <template v-slot:[`item.cuota`]="{ item }">
                  {{ formatoPrecio(item.cuota) }}
                </template>
                <template v-slot:[`item.porcentaje`]="{ item }">
                  {{ item.porcentaje + "%" }}
                  <span
                    :style="{ color: item.diferencia >= 0 ? 'green' : 'red' }"
                  >
                    ({{ item.diferencia + "%" }})
                  </span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Tabla sucursal La Paz -->
      <v-row
        v-if="dataAvances.length > 0 && dataTotalLaPaz.totalAgentes.length > 0"
        justify="center"
        class="mx-0 mt-0"
      >
        <v-col cols="12" md="12">
          <v-card class="pa-0">
            <v-card-text>
              <v-data-table
                v-if="dataTotalLaPaz.totalAgentes.length > 0"
                :headers="headers"
                :items="dataTotalLaPaz.totalAgentes"
                :items-per-page="-1"
                hide-default-footer
                no-data-text="No hay resultados"
              >
                <!-- Se agrega un encabezado con el nombre de la sucursal -->
                <template v-slot:top>
                  <div class="font-weight-bold text-subtitle-1 text-center">
                    Sucursal La Paz
                  </div>
                  <div class="pt-2 text-center">
                    <span class="font-weight-bold text-subtitle-1"
                      >Avance del mes:
                    </span>
                    {{ porcentajeDiasHabilesTranscurridos + "%" }}
                  </div>
                </template>
                <!-- Se agrega una fila al final de la tabla con los totales -->
                <template v-slot:[`body.append`]>
                  <tr>
                    <td colspan="5" class="font-weight-bold body-1 text-center">
                      <span class="text-center">Total Sucursal de La Paz:</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="text-center">
                      <span class="font-weight-bold body-1 text-center"
                        >Neto-desc:</span
                      >
                      {{
                        formatoPrecio(
                          dataTotalLaPaz.totalDeSucursal["neto-desc"]
                        )
                      }}
                    </td>
                    <td colspan="5" class="text-center">
                      <span class="font-weight-bold body-1 text-center"
                        >Velocidad:</span
                      >
                      {{
                        formatoPrecio(dataTotalLaPaz.totalDeSucursal.velocidad)
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="text-center">
                      <span class="font-weight-bold body-1 text-center"
                        >Cuota:</span
                      >
                      {{ formatoPrecio(dataTotalLaPaz.totalDeSucursal.cuota) }}
                    </td>
                    <td colspan="5" class="text-center">
                      <span class="font-weight-bold body-1 text-center"
                        >Porcentaje:</span
                      >

                      {{ dataTotalLaPaz.totalDeSucursal.porcentaje + "%" }}
                      <span
                        :style="{
                          color:
                            dataTotalLaPaz.totalDeSucursal.diferencia >= 0
                              ? 'green'
                              : 'red',
                        }"
                      >
                        ({{ dataTotalLaPaz.totalDeSucursal.diferencia + "%" }})
                      </span>
                    </td>
                  </tr>
                </template>
                <template v-slot:[`item.neto-desc`]="{ item }">
                  {{ formatoPrecio(item[`neto-desc`]) }}
                </template>
                <template v-slot:[`item.velocidad`]="{ item }">
                  {{ formatoPrecio(item.velocidad) }}
                </template>
                <template v-slot:[`item.cuota`]="{ item }">
                  {{ formatoPrecio(item.cuota) }}
                </template>
                <template v-slot:[`item.porcentaje`]="{ item }">
                  {{ item.porcentaje + "%" }}
                  <span
                    :style="{ color: item.diferencia >= 0 ? 'green' : 'red' }"
                  >
                    ({{ item.diferencia + "%" }})
                  </span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Tabla sucursal Obregón -->
      <v-row
        v-if="
          dataAvances.length > 0 && dataTotalObregon.totalAgentes.length > 0
        "
        justify="center"
        class="mx-0 mt-0"
      >
        <v-col cols="12" md="12">
          <v-card class="pa-0">
            <v-card-text>
              <v-data-table
                v-if="dataTotalObregon.totalAgentes.length > 0"
                :headers="headers"
                :items="dataTotalObregon.totalAgentes"
                :items-per-page="-1"
                hide-default-footer
                no-data-text="No hay resultados"
              >
                <!-- Se agrega un encabezado con el nombre de la sucursal -->
                <template v-slot:top>
                  <div class="font-weight-bold text-subtitle-1 text-center">
                    Sucursal Obregón
                  </div>
                  <div class="pt-2 text-center">
                    <span class="font-weight-bold text-subtitle-1"
                      >Avance del mes:
                    </span>
                    {{ porcentajeDiasHabilesTranscurridos + "%" }}
                  </div>
                </template>
                <!-- Se agrega una fila al final de la tabla con los totales -->
                <template v-slot:[`body.append`]>
                  <tr>
                    <td colspan="5" class="font-weight-bold body-1 text-center">
                      <span class="text-center"
                        >Total Sucursal de Obregón:</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="text-center">
                      <span class="font-weight-bold body-1 text-center"
                        >Neto-desc:</span
                      >
                      {{
                        formatoPrecio(
                          dataTotalObregon.totalDeSucursal["neto-desc"]
                        )
                      }}
                    </td>
                    <td colspan="5" class="text-center">
                      <span class="font-weight-bold body-1 text-center"
                        >Velocidad:</span
                      >
                      {{
                        formatoPrecio(
                          dataTotalObregon.totalDeSucursal.velocidad
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="text-center">
                      <span class="font-weight-bold body-1 text-center"
                        >Cuota:</span
                      >
                      {{
                        formatoPrecio(dataTotalObregon.totalDeSucursal.cuota)
                      }}
                    </td>
                    <td colspan="5" class="text-center">
                      <span class="font-weight-bold body-1 text-center"
                        >Porcentaje:</span
                      >

                      {{ dataTotalObregon.totalDeSucursal.porcentaje + "%" }}
                      <span
                        :style="{
                          color:
                            dataTotalObregon.totalDeSucursal.diferencia >= 0
                              ? 'green'
                              : 'red',
                        }"
                      >
                        ({{
                          dataTotalObregon.totalDeSucursal.diferencia + "%"
                        }})
                      </span>
                    </td>
                  </tr>
                </template>
                <template v-slot:[`item.neto-desc`]="{ item }">
                  {{ formatoPrecio(item[`neto-desc`]) }}
                </template>
                <template v-slot:[`item.velocidad`]="{ item }">
                  {{ formatoPrecio(item.velocidad) }}
                </template>
                <template v-slot:[`item.cuota`]="{ item }">
                  {{ formatoPrecio(item.cuota) }}
                </template>
                <template v-slot:[`item.porcentaje`]="{ item }">
                  {{ item.porcentaje + "%" }}
                  <span
                    :style="{ color: item.diferencia >= 0 ? 'green' : 'red' }"
                  >
                    ({{ item.diferencia + "%" }})
                  </span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Tabla sucursal Hermosillo -->
      <v-row
        v-if="
          dataAvances.length > 0 && dataTotalHermosillo.totalAgentes.length > 0
        "
        justify="center"
        class="mx-0 mt-0"
      >
        <v-col cols="12" md="12">
          <v-card class="pa-0">
            <v-card-text>
              <v-data-table
                v-if="dataTotalHermosillo.totalAgentes.length > 0"
                :headers="headers"
                :items="dataTotalHermosillo.totalAgentes"
                :items-per-page="-1"
                hide-default-footer
                no-data-text="No hay resultados"
              >
                <!-- Se agrega un encabezado con el nombre de la sucursal -->
                <template v-slot:top>
                  <div class="font-weight-bold text-subtitle-1 text-center">
                    Sucursal Hermosillo
                  </div>
                  <div class="pt-2 text-center">
                    <span class="font-weight-bold text-subtitle-1"
                      >Avance del mes:
                    </span>
                    {{ porcentajeDiasHabilesTranscurridos + "%" }}
                  </div>
                </template>
                <!-- Se agrega una fila al final de la tabla con los totales -->
                <template v-slot:[`body.append`]>
                  <tr>
                    <td colspan="5" class="font-weight-bold body-1 text-center">
                      <span class="text-center"
                        >Total Sucursal de Hermosillo:</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="text-center">
                      <span class="font-weight-bold body-1 text-center"
                        >Neto-desc:</span
                      >
                      {{
                        formatoPrecio(
                          dataTotalHermosillo.totalDeSucursal["neto-desc"]
                        )
                      }}
                    </td>
                    <td colspan="5" class="text-center">
                      <span class="font-weight-bold body-1 text-center"
                        >Velocidad:</span
                      >
                      {{
                        formatoPrecio(
                          dataTotalHermosillo.totalDeSucursal.velocidad
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="text-center">
                      <span class="font-weight-bold body-1 text-center"
                        >Cuota:</span
                      >
                      {{
                        formatoPrecio(dataTotalHermosillo.totalDeSucursal.cuota)
                      }}
                    </td>
                    <td colspan="5" class="text-center">
                      <span class="font-weight-bold body-1 text-center"
                        >Porcentaje:</span
                      >

                      {{ dataTotalHermosillo.totalDeSucursal.porcentaje + "%" }}
                      <span
                        :style="{
                          color:
                            dataTotalHermosillo.totalDeSucursal.diferencia >= 0
                              ? 'green'
                              : 'red',
                        }"
                      >
                        ({{
                          dataTotalHermosillo.totalDeSucursal.diferencia + "%"
                        }})
                      </span>
                    </td>
                  </tr>
                </template>
                <template v-slot:[`item.neto-desc`]="{ item }">
                  {{ formatoPrecio(item[`neto-desc`]) }}
                </template>
                <template v-slot:[`item.velocidad`]="{ item }">
                  {{ formatoPrecio(item.velocidad) }}
                </template>
                <template v-slot:[`item.cuota`]="{ item }">
                  {{ formatoPrecio(item.cuota) }}
                </template>
                <template v-slot:[`item.porcentaje`]="{ item }">
                  {{ item.porcentaje + "%" }}
                  <span
                    :style="{ color: item.diferencia >= 0 ? 'green' : 'red' }"
                  >
                    ({{ item.diferencia + "%" }})
                  </span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import pako from "pako";
import Papa from "papaparse";
export default {
  data() {
    return {
      items: [],
      dataAvances: [],
      datosFiltrados: [],

      fechaInicial: "",
      fechaFin: "",
      clienteSelected: {},
      selectedSucursales: [
        "Mexicali",
        "Tijuana",
        "Ensenada",
        "LaPaz",
        "Obregon",
        "Hermosillo",
      ],
      selectedMes: new Date().toISOString().slice(0, 7),
      cuotas_vendedores: {},

      headers: [
        {
          text: "Agente",
          value: "agente",

          sortable: false,
        },
        {
          text: `Neto-desc`,
          value: "neto-desc",
          sortable: false,
        },
        {
          text: `Velocidad`,
          value: "velocidad",

          sortable: false,
        },
        {
          text: "Cuota",
          value: "cuota",

          sortable: false,
        },
        {
          text: "%",
          value: "porcentaje",

          sortable: false,
        },
      ],
      sucursales: [
        {
          text: "Mexicali",
          value: "Mexicali",
        },
        {
          text: "Tijuana",
          value: "Tijuana",
        },
        {
          text: "Ensenada",
          value: "Ensenada",
        },
        {
          text: "La Paz",
          value: "LaPaz",
        },
        {
          text: "Obregón",
          value: "Obregon",
        },
        {
          text: "Hermosillo",
          value: "Hermosillo",
        },
      ],
      headersTotalSucursales: [
        {
          text: `Neto-desc`,
          value: "neto-desc",
          sortable: false,
        },
        {
          text: `Velocidad`,
          value: "velocidad",
          sortable: false,
        },
        {
          text: "Cuota",
          value: "cuota",
          sortable: false,
        },
        {
          text: "%",
          value: "porcentaje",
          align: "center",
          sortable: false,
        },
      ],
      loadingBtn: false,
      menuMes: false,
    };
  },
  methods: {
    async getReporte() {
      this.loadingBtn = true;

      try {
        const response = await axios.get(
          `/varios/get_json?nombre=cuotas_vendedores`
        );

        this.cuotas_vendedores = response.data.objeto;
        console.log(this.cuotas_vendedores);
      } catch (error) {
        console.error("Error al obtener las cuotas de vendedores:", error);
      }

      let sucursalesString = "";
      if (this.selectedSucursales.length !== 0) {
        this.selectedSucursales.forEach((sucursal) => {
          sucursalesString = sucursalesString + `&sucursales=${sucursal}`;
        });
      }

      try {
        const response = await axios.get(
          `/reportes/pivot_table?fecha_inicial=${this.fechaInicial}&fecha_final=${this.fechaFin}${sucursalesString}&indice=codigo&valor=neto-desc`,
          {
            responseType: "arraybuffer", // se especifica el tipo de respuesta
          }
        );

        // Descomprimir el archivo gzip
        const decompressedData = pako.ungzip(response.data, { to: "string" });

        // Leer el archivo CSV
        const csvData = Papa.parse(decompressedData, {
          header: true, // si tu archivo CSV tiene encabezados
          dynamicTyping: true, // para convertir automáticamente los campos numéricos a números
        });
        const data = csvData.data;
        data.pop();
        console.log("DATA", data);
        this.dataAvances = data;

        if (data.length > 0) {
          this.datosFiltrados = data.filter((venta) =>
            Object.prototype.hasOwnProperty.call(
              this.cuotas_vendedores,
              venta.agente
            )
          );
          console.log("DATOS FILTRADOS", this.datosFiltrados);

          //Los datos se filtran por sucursal y se calculan los valores de los agentes en sus respectivas variables computadas

          //   console.log("ENSENADA", this.dataTotalEnsenada);

          this.loadingBtn = false;
        } else {
          this.loadingBtn = false;
          alert("No hay registros para mostrar");
        }
        this.loadingBtn = false;
      } catch (error) {
        this.loadingBtn = false;
        alert(`Error al obtener los datos`);
        console.log(error.response.data);
      }
    },
    //** calcula el total por agente de una sucursal y tambien total de esa sucursal */
    calcularTotalPorSucursal(sucursal) {
      const ventasSucursal = this.datosFiltrados.filter(
        (venta) => venta.sucursal === sucursal
      );
      const totalPorAgente = {};

      ventasSucursal.forEach((venta) => {
        if (!totalPorAgente[venta.agente]) {
          totalPorAgente[venta.agente] = {
            "neto-desc": 0,
            velocidad: 0,
            cuota: 0,
            porcentaje: 0,
            diferencia: 0, //se crea la variable para utilizarse en la diferencia
          };
        }
        totalPorAgente[venta.agente]["neto-desc"] += venta["neto-desc"];
      });

      Object.keys(totalPorAgente).forEach((agente) => {
        const netoDesc = totalPorAgente[agente]["neto-desc"];
        const cuota =
          this.cuotas_vendedores[agente][
            new Date(this.selectedMes).getMonth() + 1 //SE CAMBIO EL +2 POR +1
          ] * 1000;
        const velocidad =
          (netoDesc / this.diasHabilesTranscurridos) * this.diasHabilesTotales;
        const porcentaje = (netoDesc / cuota) * 100;

        totalPorAgente[agente].velocidad = velocidad;
        totalPorAgente[agente].cuota = cuota;
        totalPorAgente[agente].porcentaje = porcentaje;
        totalPorAgente[agente].diferencia =
          porcentaje - this.porcentajeDiasHabilesTranscurridos; //igualamos la diferencia a porcentaje y posteriormente hacer la resta
      });

      const totalAgentes = Object.keys(totalPorAgente).map((agente) => ({
        agente,
        "neto-desc": Math.round(totalPorAgente[agente]["neto-desc"]),
        velocidad: Math.round(totalPorAgente[agente].velocidad),
        cuota: totalPorAgente[agente].cuota,
        porcentaje: Math.round(totalPorAgente[agente].porcentaje),
        diferencia: Math.round(totalPorAgente[agente].diferencia), //usamos la variable para indicarle que pondra el porcentaje a cada agente, retornando el valor redondeado mas cercano
      }));

      //Obtenemos los totales por sucursal
      const totalDeSucursal = {
        "neto-desc": 0,
        velocidad: 0,
        cuota: 0,
        porcentaje: 0,
        diferencia: 0,
      };

      totalAgentes.forEach((agenteData) => {
        totalDeSucursal["neto-desc"] += agenteData["neto-desc"];
        totalDeSucursal.velocidad += agenteData.velocidad;
        totalDeSucursal.cuota += agenteData.cuota;
        //totalDeSucursal.diferencia += agenteData.diferencia; //finalmente hacemos una suma que puede ser numerica o una concatenacion de cadenas
      });

      if (totalDeSucursal.cuota !== 0) {
        totalDeSucursal.porcentaje = Math.round(
          (totalDeSucursal["neto-desc"] / totalDeSucursal.cuota) * 100
        );
      }
      //nueva linea agregada
      totalDeSucursal.diferencia =
        totalDeSucursal.porcentaje - this.porcentajeDiasHabilesTranscurridos;
      // console.log("TOTAL DE SUCURSAL", totalDeSucursal); //verificacion

      return { totalAgentes, totalDeSucursal };
    },
    //** obtiene la lista de todas las sucursales y calcula el total de todas las sucursales juntas */
    calcularTotalDeSucursal(sucursal) {
      const dataTotalSucursal =
        this.calcularTotalPorSucursal(sucursal).totalAgentes;
      const total = {
        "neto-desc": 0,
        velocidad: 0,
        cuota: 0,
        porcentaje: 0,
        diferencia: 0,
      };

      dataTotalSucursal.forEach((agenteData) => {
        total["neto-desc"] += agenteData["neto-desc"];
        total.velocidad += agenteData.velocidad;
        total.cuota += agenteData.cuota;
      });
      total.porcentaje = (total["neto-desc"] / total.cuota) * 100;

      total.diferencia =
        total.porcentaje - this.porcentajeDiasHabilesTranscurridos;

      return total;
    },
    calcularTotalDeTodasSucursales() {
      const sucursales = [
        ...new Set(this.datosFiltrados.map((venta) => venta.sucursal)),
      ];
      const total = {
        "neto-desc": 0,
        velocidad: 0,
        cuota: 0,
        porcentaje: 0,
        diferencia: 0,
      };

      sucursales.forEach((sucursal) => {
        const totalSucursal = this.calcularTotalDeSucursal(sucursal);
        total["neto-desc"] += totalSucursal["neto-desc"];
        total.velocidad += totalSucursal.velocidad;
        total.cuota += totalSucursal.cuota;
      });
      if (total.cuota !== 0) {
        total.porcentaje = Math.floor((total["neto-desc"] / total.cuota) * 100);
      }
      total.diferencia =
        total.porcentaje - this.porcentajeDiasHabilesTranscurridos;

      return [total];
    },
    calcularDiasHabiles(fecha) {
      const [year, month] = fecha.split("-").map(Number);
      const date = new Date(year, month - 1, 1);
      let totalDiasHabiles = 0;
      let diasHabilesTranscurridos = 0;
      const today = new Date();

      today.setHours(0, 0, 0, 0);

      while (date.getMonth() === month - 1) {
        const dayOfWeek = date.getDay();
        const isBusinessDay = dayOfWeek >= 1 && dayOfWeek <= 6; // Lunes a Sábado
        if (isBusinessDay) {
          totalDiasHabiles++;
          if (date <= today) {
            diasHabilesTranscurridos++;
          }
        }
        date.setDate(date.getDate() + 1);
      }

      return { totalDiasHabiles, diasHabilesTranscurridos };
    },
    formatoPrecio(precioProducto) {
      // Formatear el número utilizando una función personalizada
      return precioProducto.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  computed: {
    dataTotalSucursales() {
      return this.calcularTotalDeTodasSucursales();
    },
    dataTotalMexicali() {
      return this.calcularTotalPorSucursal("MEXICALI");
    },
    dataTotalTijuana() {
      return this.calcularTotalPorSucursal("TIJUANA");
    },
    dataTotalEnsenada() {
      return this.calcularTotalPorSucursal("ENSENADA");
    },
    dataTotalLaPaz() {
      return this.calcularTotalPorSucursal("LA_PAZ");
    },
    dataTotalObregon() {
      return this.calcularTotalPorSucursal("OBREGON");
    },
    dataTotalHermosillo() {
      return this.calcularTotalPorSucursal("HERMOSILLO");
    },
    diasHabilesTotales() {
      return this.calcularDiasHabiles(this.selectedMes).totalDiasHabiles;
    },
    diasHabilesTranscurridos() {
      return this.calcularDiasHabiles(this.selectedMes)
        .diasHabilesTranscurridos;
    },
    porcentajeDiasHabilesTranscurridos() {
      const { totalDiasHabiles, diasHabilesTranscurridos } =
        this.calcularDiasHabiles(this.selectedMes);
      return Math.floor((diasHabilesTranscurridos / totalDiasHabiles) * 100);
    },
    ...mapGetters(["user"]),
  },
  created() {
    // this.getClientes();

    const today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, 10);

    // Dividimos la fecha en año y mes
    const [anio, mes] = today.split("-");

    // Creamos una nueva fecha con el primer día del mes
    const primerDia = new Date(anio, mes - 1, 1);
    // Creamos una nueva fecha con el primer día del siguiente mes y restamos 1 día para obtener el último día del mes actual
    const ultimoDia = new Date(anio, mes, 0);

    // Formateamos la fecha como 'AAAA-MM-DD'
    this.fechaInicial = primerDia.toISOString().slice(0, 10);
    this.fechaFin = ultimoDia.toISOString().slice(0, 10);
  },
  components: {},
  watch: {
    selectedMes(nuevoValor) {
      //   console.log(nuevoValor);

      // Dividimos la fecha en año y mes
      const [anio, mes] = nuevoValor.split("-");

      // Creamos una nueva fecha con el primer día del mes
      const primerDia = new Date(anio, mes - 1, 1);
      // Creamos una nueva fecha con el primer día del siguiente mes y restamos 1 día para obtener el último día del mes actual
      const ultimoDia = new Date(anio, mes, 0);

      // Formateamos la fecha como 'AAAA-MM-DD'
      this.fechaInicial = primerDia.toISOString().slice(0, 10);
      this.fechaFin = ultimoDia.toISOString().slice(0, 10);
      console.log(
        "fecha inicial",
        this.fechaInicial,
        "fecha final",
        this.fechaFin
      );

      this.dataAvances = [];
    },
  },
};
</script>

<style>
/*.container {
  padding: 0px;
}*/
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 15px !important;
}
</style>
