<template>
  <v-container fluid>
    <v-card-title class="text-center teal lighten-3 accent-4 white--text">
      Faltantes Cerrados
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer />
      <v-select
        class="mt-5"
        label="Filtrar por estado"
        v-model="filtroEstado"
        :items="listaEstados"
      ></v-select>
    </v-card-title>
    <v-tabs v-model="selectedTab" color="teal lighten-3 accent-4" left>
      <v-tab
        v-for="(sucursal, index) in itemsSucursalesDisponibles"
        :key="index"
      >
        <div v-if="sucursal.sucursal === 'LaPaz'">La Paz</div>
        <div v-else>
          {{ sucursal.sucursal }}
        </div>
      </v-tab>
      <v-tab-item v-for="(sucursal, index) in itemsNombre" :key="index">
        <v-data-table
          :headers="headers"
          :items="selectedItems"
          :search="search"
          :footer-props="{ 'items-per-page-text': 'Faltantes por página' }"
          :header-props="{ 'sort-by-text': 'Ordenar por' }"
          no-data-text="No se encontraron faltantes"
          no-results-text="No se encontraron faltantes"
          :item-class="itemRowBackground"
        >
          <template v-slot:[`item.acciones`]="{ item }">
            <AccionesFaltante :item="item" :usuarios="itemsUsuarios" />
          </template>
          <template v-slot:[`item.seguimiento_automatico`]="{ item }">
            <SeguimientoAutomatico :item="item" />
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import AccionesFaltante from "@/components/AccionesFaltante.vue";
import SeguimientoAutomatico from "@/components/SeguimientoAutomatico.vue";
import { mapGetters } from "vuex";
export default {
  props: ["items", "usuarios", "clientes", "itemsCerrados"],
  data() {
    return {
      selectedTab: 0,
      search: "",
      filtroEstado: "",
      listaEstados: [
        {
          text: "Sin filtro",
          value: "",
        },
        {
          text: "Facturado",
          value: "Facturado",
        },
        {
          text: "Cancelado",
          value: "Cancelado",
        },
      ],
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Código de producto",
          value: "codigo_producto",
        },
        {
          text: "Nombre",
          value: "nombre",
        },
        {
          text: "Cantidad",
          value: "cantidad",
        },
        {
          text: "Cliente",
          value: "cliente",
        },
        {
          text: "Duración",
          value: "duracion",
        },
        {
          text: "Estado",
          value: "estado",
          filter: (value) => {
            if (!this.filtroEstado) return true;

            return value === this.filtroEstado;
          },
        },
        {
          text: "Detalle",
          value: "detalle",
        },
        {
          text: "Laboratorio",
          value: "laboratorio",
        },

        {
          text: "Fecha cerrado",
          value: "fecha_cerrado",
        },
        {
          text: "Vendedor",
          value: "vendedor",
        },
        {
          text: "Acciones",
          value: "acciones",
          sortable: false,
        },
      ],
      ordenSucursales: [
        "Mexicali",
        "Tijuana",
        "Ensenada",
        "LaPaz",
        "Obregon",
        "Hermosillo",
      ],
      itemsSucursalesDisponibles: [],
      itemsFaltantes: this.items,
      itemsUsuarios: this.usuarios,
    };
  },
  methods: {
    obtenerArregloSucursalesDisponibles() {
      if (!this.itemsFaltantes || this.itemsFaltantes.length === 0) {
        console.log("No hay faltantes", this.itemsFaltantes);
        // No hay faltantes, limpiar el arreglo de sucursales disponibles
        this.itemsSucursalesDisponibles = [];
        return;
      }

      // Extraer sucursales permitidas desde los permisos del usuario actual
      const sucursalesPermitidas = [
        ...new Set(
          this.currentUser.permisos
            .split(" ") // Dividir los permisos por espacio
            .map((permiso) => permiso.split(":")[0]) // Extraer la sucursal antes de ":"
        ),
      ];

      // Agrupar faltantes por sucursal
      const agrupadosPorSucursal = this.itemsFaltantes.reduce(
        (acumulador, faltante) => {
          if (!acumulador[faltante.sucursal]) {
            acumulador[faltante.sucursal] = [];
          }
          acumulador[faltante.sucursal].push(faltante);
          return acumulador;
        },
        {}
      );

      if (this.currentUser.permisos.includes("ADMINISTRADOR")) {
        // Si el usuario es administrador, mostrar todas las sucursales incluso si no tienen faltantes
        this.itemsSucursalesDisponibles = this.ordenSucursales.map(
          (sucursal) => ({
            sucursal,
            faltantes: agrupadosPorSucursal[sucursal] || [], // Si no hay faltantes para la sucursal, dejar el array vacío
          })
        );

        const nuevoArreglo = this.itemsFaltantes.sort((a, b) => {
          const indiceA = this.ordenSucursales.indexOf(a.sucursal);
          const indiceB = this.ordenSucursales.indexOf(b.sucursal);
          return indiceA - indiceB;
        });
        this.itemsSucursalesDisponibles = nuevoArreglo;

        /*
        // Filtrar sucursales sin faltantes si no es administrador
        this.itemsSucursalesDisponibles = this.esAdministrador
          ? todasSucursales // Mostrar todas las sucursales para administrador
          : todasSucursales.filter((item) => item.faltantes.length > 0); // Filtrar sucursales sin faltantes para otros*/
      } else if (this.currentUser.permisos.includes("faltantes:facturar")) {
        // No administrador: Filtrar las sucursales disponibles
        const todasSucursales = this.ordenSucursales.map((sucursal) => ({
          sucursal,
          faltantes: agrupadosPorSucursal[sucursal] || [], // Si no hay faltantes, array vacío
        }));

        this.itemsSucursalesDisponibles = todasSucursales.filter(
          (item) =>
            item.faltantes.length > 0 ||
            sucursalesPermitidas.includes(item.sucursal)
        );
        const nuevoArreglo = this.itemsFaltantes.sort((a, b) => {
          const indiceA = this.ordenSucursales.indexOf(a.sucursal);
          const indiceB = this.ordenSucursales.indexOf(b.sucursal);
          return indiceA - indiceB;
        });
        this.itemsSucursalesDisponibles = nuevoArreglo;
      } else {
        // No administrador: Filtrar las sucursales disponibles
        const todasSucursales = this.ordenSucursales.map((sucursal) => ({
          sucursal,
          faltantes: agrupadosPorSucursal[sucursal] || [], // Si no hay faltantes, array vacío
        }));

        this.itemsSucursalesDisponibles = todasSucursales.filter(
          (item) =>
            item.faltantes.length > 0 ||
            sucursalesPermitidas.includes(item.sucursal)
        );
      }
    },

    getNombreVendedor(vendedorId) {
      const vendedorEncontrado = this.usuarios.find(
        (usuario) => usuario.id === vendedorId
      );
      if (vendedorEncontrado) {
        return vendedorEncontrado.nombre + " ";
      }
      return "";
    },
    getNombreCliente(clienteId) {
      const clienteEncontrado = this.clientes.find(
        (cliente) => cliente.id === clienteId
      );
      if (clienteEncontrado) {
        return clienteEncontrado.razon_social + " ";
      }
      return "";
    },
    getAntiguedad(fecha_creacion) {
      const now = new Date();
      const fecha = new Date(fecha_creacion);
      const diffMs = now - fecha;
      const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
      const diffHrs = Math.floor(
        (diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const diffMins = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

      if (diffDays > 0) {
        return `${diffDays} día${diffDays > 1 ? "s" : ""}`;
      } else if (diffHrs > 0) {
        return `${diffHrs} hora${diffHrs > 1 ? "s" : ""}`;
      } else {
        return `${diffMins} minuto${diffMins > 1 ? "s" : ""}`;
      }
    },
    getDuracion(fecha_creacion, fecha_cerrado) {
      const fechaCreacion = new Date(fecha_creacion);
      const fechaCerrado = new Date(fecha_cerrado);
      const diffMs = fechaCerrado - fechaCreacion;
      const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
      const diffHrs = Math.floor(
        (diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const diffMins = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

      if (diffDays > 0) {
        return `${diffDays} día${diffDays > 1 ? "s" : ""}`;
      } else if (diffHrs > 0) {
        return `${diffHrs} hora${diffHrs > 1 ? "s" : ""}`;
      } else {
        return `${diffMins} minuto${diffMins > 1 ? "s" : ""}`;
      }
    },
  },
  computed: {
    itemsNombre() {
      return this.itemsSucursalesDisponibles.map((sucursal) => {
        const faltantes = sucursal.faltantes.map((faltante) => {
          const nuevoFaltante = { ...faltante };
          nuevoFaltante.vendedor = this.getNombreVendedor(faltante.vendedor);
          nuevoFaltante.cliente = this.getNombreCliente(faltante.cliente);
          nuevoFaltante.duracion = this.getDuracion(
            faltante.fecha_creacion,
            faltante.fecha_cerrado
          );
          nuevoFaltante.fecha_cerrado = this.getAntiguedad(
            faltante.fecha_cerrado
          );
          return nuevoFaltante;
        });

        return { ...sucursal, faltantes };
      });
    },
    selectedItems() {
      //Se envia el nombre de la tab seleccionada a la view de faltantes para que pueda revisar envios e inventario
      this.$emit(
        "enviarTab",
        this.itemsSucursalesDisponibles[this.selectedTab].sucursal
      );
      if (this.selectedTab >= 1 && this.selectedTab <= 6) {
        return this.itemsNombre[this.selectedTab].faltantes;
      } else {
        return this.itemsNombre[this.selectedTab].faltantes || [];
      }
    },
    itemRowBackground() {
      return (item) => {
        const estado = item.estado;
        switch (estado) {
          case "Cancelado":
            return "red lighten-4";
          case "Facturado":
            return "teal lighten-4";
          case "En Camino":
            return "yellow lighten-4";
          case "Requiere Aclaracion":
            return "orange lighten-4";
          case "En Calendario":
            return "blue lighten-4";
          case "Nuevo Faltante":
            return "green lighten-4";
          case "En Inventario":
            return "indigo lighten-4";
          default:
            return "";
        }
      };
    },
    ...mapGetters(["user", "currentUser"]),
  },
  mounted() {
    console.log("Datos iniciales", this.items);
    this.obtenerArregloSucursalesDisponibles();
  },
  components: { AccionesFaltante, SeguimientoAutomatico },
};
</script>
