<template>
  <v-dialog
    fullscreen
    transition="dialog-bottom-transition"
    max-width="80%"
    v-model="dialog"
  >
    <v-card>
      <v-container>
        <v-btn
          class="close-btn"
          icon
          value="log in"
          color="blue darken-1"
          text
          @click="cerrar()"
        >
          <v-icon dark> mdi-close </v-icon>
        </v-btn>
        <v-card-title>Modificar prepedido: {{ prepedido.id }}</v-card-title>
        <v-card-text>
          <v-container>
            <div>
              <!-- Tabla de productos -->
              <v-data-table
                class="mb-3"
                :headers="headers"
                :items="productosDePrepedido"
                :items-per-page="productosDePrepedido.length"
                hide-default-footer
                no-data-text="No hay productos agregados"
              >
                <template v-slot:[`item.editar`]="{ item }">
                  <v-btn
                    icon
                    color="blue darken-1"
                    text
                    @click="abrirCampoEdicion(item)"
                  >
                    <v-icon dark v-if="!item.modoEdicion"
                      >mdi-pencil-outline</v-icon
                    >
                    <v-icon dark v-else>mdi-close</v-icon>
                  </v-btn>
                </template>

                <template v-slot:[`item.cantidad`]="{ item }">
                  <div v-if="item.modoEdicion">
                    <v-text-field
                      v-model="item.nuevaCantidad"
                      autofocus
                      type="number"
                      inputmode="numeric"
                      label="Cantidad"
                      placeholder="Ingrese la nueva cantidad"
                      step="1"
                      :dense="$vuetify.breakpoint.smAndDown"
                      :hide-details="true"
                      @keyup.enter="guardarCantidadModificada(item)"
                    />
                    <v-btn
                      class="mt-2"
                      small
                      color="green darken-1"
                      text
                      @click="guardarCantidadModificada(item)"
                    >
                      <v-icon green>mdi-check-circle-outline</v-icon>
                    </v-btn>
                    <v-btn
                      class="mt-2"
                      small
                      color="red darken-1"
                      text
                      @click="cancelarEdicion(item)"
                    >
                      <v-icon red>mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </div>
                  <div v-else>
                    {{ item.cantidad }}
                  </div>
                </template>

                <template v-slot:[`item.eliminar`]="{ item }">
                  <v-btn
                    icon
                    color="blue darken-1"
                    text
                    @click="eliminarProductoTemp(item)"
                  >
                    <v-icon dark>mdi-delete-outline</v-icon>
                  </v-btn>
                </template>
                <template v-slot:[`item.existencia`]="{ item }">
                  <template v-if="item.existencia === 0">
                    <div
                      v-if="
                        item.fechasLlegada.fechaProxima.length === 0 &&
                        item.fechasLlegada.fechasDeLlegada.length === 0
                      "
                    >
                      No hay existencias en camino
                    </div>
                    <div
                      v-else-if="
                        item.fechasLlegada.fechasDeLlegada.length === 0
                      "
                    >
                      <v-list-group>
                        <template v-slot:activator>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                Llegada:
                                {{
                                  item.fechasLlegada.fechaProxima[0]
                                    .fecha_llegada
                                }}
                                cantidad:
                                {{
                                  item.fechasLlegada.fechaProxima[0].cantidad
                                }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              No hay mas existencias en camino
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-group>
                    </div>
                    <div
                      v-else-if="item.fechasLlegada.fechaProxima.length === 0"
                    >
                      <v-list-group>
                        <template v-slot:activator>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                No hay envios que cuenten con esa cantidad
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                        <v-list-item
                          v-for="dato in item.fechasLlegada.fechasDeLlegada"
                          :key="dato.id"
                        >
                          <v-list-item-content>
                            <v-list-item-title>
                              Llegada:
                              {{ dato.fecha_llegada }}
                              cantidad:
                              {{ dato.cantidad }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-group>
                    </div>
                    <div v-else>
                      <!-- Lista de envios en camino cuando no hay existencia  -->
                      <v-list-group>
                        <template v-slot:activator>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                Llegada:
                                {{
                                  item.fechasLlegada.fechaProxima[0]
                                    .fecha_llegada
                                }}
                                cantidad:
                                {{
                                  item.fechasLlegada.fechaProxima[0].cantidad
                                }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                        <v-list-item
                          v-for="dato in item.fechasLlegada.fechasDeLlegada"
                          :key="dato.id"
                        >
                          <v-list-item-content>
                            <v-list-item-title>
                              Llegada:
                              {{ dato.fecha_llegada }}
                              cantidad:
                              {{ dato.cantidad }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-group>
                    </div>
                  </template>
                  <template v-else>
                    {{ item.existencia }}
                  </template>
                </template>
                <template v-slot:[`item.precio`]="{ item }">
                  {{
                    "$" + formatoPrecioDescuento(item.precio, item.descuento)
                  }}
                </template>
                <template v-slot:[`item.subtotal`]="{ item }">
                  {{
                    "$" +
                    calcularSubtotal(item)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }}
                  <span v-if="item.descuento > 0"
                    >(-{{ item.descuento }}%)</span
                  >
                </template>
              </v-data-table>
              <div class="texto-total mt-3 mb-3 mr-6">
                <h3>Total: {{ calcularTotal() }}</h3>
              </div>

              <!-- Tabla faltantes -->
              <div class="text-center mt-4">
                <h3>Productos faltantes</h3>
              </div>
              <v-data-table
                class="color-tabla mb-3 mt-3"
                :headers="headers2"
                :items="mostrarProductosFaltantes()"
                :items-key="codigo_producto"
                hide-default-footer
                no-data-text="No hay productos faltantes"
              >
                <template v-slot:[`item.producto_faltante`]="{ item }">
                  <div>{{ item.producto_faltante }}</div>
                </template>
                <template v-slot:[`item.nombre_faltante`]="{ item }">
                  <div>{{ item.nombre_faltante }}</div>
                </template>
                <template v-slot:[`item.cantidad_faltante`]="{ item }">
                  <div v-if="item.modoEdicion">
                    <v-text-field
                      v-model="item.nuevaCantidad"
                      autofocus
                      type="number"
                      inputmode="numeric"
                      label="Cantidad"
                      placeholder="Ingrese la nueva cantidad"
                      step="1"
                      :dense="$vuetify.breakpoint.smAndDown"
                      :hide-details="true"
                      @keyup.enter="guardarCantidadModificadaFaltante(item)"
                    />
                    <v-btn
                      class="mt-2"
                      small
                      color="green darken-1"
                      text
                      @click="guardarCantidadModificadaFaltante(item)"
                    >
                      <v-icon green>mdi-check-circle-outline</v-icon>
                    </v-btn>
                    <v-btn
                      class="mt-2"
                      small
                      color="red darken-1"
                      text
                      @click="cancelarEdicionFaltante(item)"
                    >
                      <v-icon red>mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </div>
                  <div v-else>{{ item.cantidad_faltante }}</div>
                </template>
                <template v-slot:[`item.sucursal_faltante`]="{ item }">
                  <div>{{ item.sucursal_faltante }}</div>
                </template>
                <template v-slot:[`item.editar_faltante`]="{ item }">
                  <div>
                    <v-btn
                      color="primary"
                      text
                      small
                      @click="editarFaltante(item)"
                    >
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                  </div>
                </template>
                <template v-slot:[`item.eliminar`]="{ item }">
                  <div>
                    <v-btn
                      color="primary"
                      text
                      small
                      @click="eliminarFaltante(item)"
                    >
                      <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
              <v-btn
                class="mt-4 mb-4"
                :loading="isLoadingFaltanes"
                :disabled="productosFaltantes.length === 0"
                color="primary"
                @click="enviarFaltantesAlBackend()"
              >
                Crear Faltantes
              </v-btn>
            </div>
            <form ref="form" @submit.prevent="modificarPrepedido()">
              <!-- Row para agregar productos -->
              <v-row>
                <v-col cols="12" md="5" lg="5">
                  <v-autocomplete
                    v-model="codigo_producto"
                    :items="productos"
                    item-text="Description"
                    item-value="codigo"
                    label="Buscar producto"
                    hide-no-data
                    hide-overlay
                    placeholder="Selecciona producto"
                    :searchable="true"
                    outlined
                    clearable
                  >
                    <template #item="{ item }">
                      <div>
                        <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          item.codigo
                        }}</v-list-item-subtitle>
                      </div>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="3" lg="3">
                  <v-text-field
                    v-model="cantidad_producto"
                    label="Cantidad"
                    placeholder="Agrega una cantidad"
                    type="number"
                    step="1"
                    outlined
                    @input="validarEntero"
                  />
                </v-col>
                <v-col cols="12" md="4" lg="4">
                  <v-btn
                    class="mt-3"
                    :loading="isLoading"
                    :disabled="!id_vendedor"
                    @click="addProductToBackend"
                    >Agregar</v-btn
                  >
                  <!-- 

                   -->
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" lg="6">
                  <v-autocomplete
                    v-model="clienteSelected"
                    :items="clientes"
                    :disabled="!id_vendedor"
                    item-text="Description"
                    item-value="id"
                    :return-object="true"
                    outlined
                    clearable
                    label="Código de cliente"
                    append-icon="mdi-barcode"
                    no-data-text="No hay clientes para este usuario"
                    placeholder="Busca el cliente"
                    required
                  >
                    <template #item="{ item }">
                      <div>
                        <v-list-item-title>{{ item.codigo }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          item.rfc
                        }}</v-list-item-subtitle>
                        <v-list-item-subtitle>{{
                          item.razon_social
                        }}</v-list-item-subtitle>
                      </div>
                    </template>
                  </v-autocomplete>
                  <v-autocomplete
                    v-model="VerLocalizaciones"
                    :items="localizaciones"
                    :disabled="!clienteSelected"
                    item-text="descripcion"
                    :return-object="true"
                    outlined
                    clearable
                    label="Localizaciones"
                    append-icon="mdi-map-marker"
                    no-data-text="No hay localizaciones para este cliente"
                    placeholder="Busca una localización"
                    required
                  >
                    <template #item="{ item }">
                      <div>
                        <v-list-item-title>{{
                          item.descripcion
                        }}</v-list-item-title>
                      </div>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" lg="6">
                  <v-select
                    outlined
                    v-model="id_vendedor"
                    :items="userIsAdmin ? getFilteredUsuarios : usuarios"
                    :item-text="(user) => user.nombre"
                    :item-value="(user) => user.id"
                    :filterable="true"
                    :label="'Vendedor'"
                    :disabled="!userIsAdmin"
                    @change="cambioDeVendedor"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4" lg="4">
                  <v-select
                    outlined
                    v-model="contado"
                    :items="opcionesContado"
                    :label="'Contado'"
                  >
                  </v-select>
                  <v-select
                    outlined
                    v-model="prioridad"
                    :items="opcionesContado"
                    :label="'Prioridad'"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="8" lg="8">
                  <v-textarea
                    outlined
                    label="Observaciones"
                    v-model="observaciones"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-btn type="submit" color="teal lighten-2" text
                >Modificar prepedido</v-btn
              >
            </form>
          </v-container>
        </v-card-text>
      </v-container>
    </v-card>

    <template v-slot:activator="{ on, attrs }">
      <v-btn small v-on="on" v-bind="attrs"
        >Modificar <v-icon size="small">mdi-file-document-edit</v-icon></v-btn
      >
    </template>
  </v-dialog>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  props: ["entries", "entriesClientes", "usuarios", "prepedido"],
  data() {
    return {
      headers: [
        {
          text: "Producto",
          value: "producto",
          sortable: false,
        },
        {
          text: "Nombre",
          value: "nombre",
          sortable: false,
        },
        {
          text: "Cantidad",
          value: "cantidad",
          sortable: false,
        },
        {
          text: "Existencia",
          value: "existencia",
          sortable: false,
        },
        {
          text: "Precio",
          value: "precio",
          sortable: false,
        },
        {
          text: "Subtotal",
          value: "subtotal",
          sortable: false,
        },
        {
          text: "Editar cantidad",
          value: "editar",
          sortable: false,
        },
        {
          text: "Eliminar",
          value: "eliminar",
          sortable: false,
        },
      ],
      opcionesContado: [
        {
          text: "Sí",
          value: true,
        },
        {
          text: "No",
          value: false,
        },
      ],
      headers2: [
        {
          text: "Producto",
          value: "producto_faltante",
          sortable: false,
        },
        {
          text: "Nombre",
          value: "nombre_faltante",
          sortable: false,
        },
        {
          text: "Cantidad Faltante",
          value: "cantidad_faltante",
          sortable: false,
        },
        {
          text: "Sucursal",
          value: "sucursal_faltante",
          sortable: false,
        },
        {
          text: "Editar cantidad",
          value: "editar_faltante",
          sortable: false,
        },
        {
          text: "Eliminar",
          value: "eliminar",
          sortable: false,
        },
      ],
      contado: true,
      prioridad: false,
      dialog: false,
      productosDePrepedido: [], //Se asignan los productos de prepedido en el metodo loadObjects
      prepedidosTemp: [],
      clientesSucursalVendedor: [],
      codigo_producto: "",
      id_vendedor: null,
      id_cliente: "",
      clienteSelected: this.prepedido.cliente || null,
      cantidad_producto: "",
      observaciones: "",
      descriptionLimit: 60,
      //prepedidoId: this.prepedido.id,
      fecha: this.prepedido.fecha,
      llegadaProducto: [],
      sucursal: "",
      isLoading: false,
      clientesUsuario: [],
      userIsAdmin: false,
      //prepedidoId: null,
      //total: "",
      //alertErrorDisplayed: false,
      //idPrepedido: [],
      //clientes: [],
      productosFaltantes: {},
      VerLocalizaciones: null,
      localizaciones: [],
      isLoadingFaltanes: false,
    };
  },
  methods: {
    mostrarProductosFaltantes() {
      return this.productosFaltantes[this.prepedido.id] || [];
    },

    //metodo para guardar la tabla de faltantes en localstorage
    guardarFaltantesEnLocalStorage() {
      localStorage.setItem(
        "productosFaltantes",
        JSON.stringify(this.productosFaltantes)
      );
    },

    cargarFaltantesDeLocalStorage() {
      const faltantesGuardados = localStorage.getItem("productosFaltantes");
      this.productosFaltantes = faltantesGuardados
        ? JSON.parse(faltantesGuardados)
        : {};
    },

    editarFaltante(producto) {
      // Restablecer el producto sin cambiar la cantidad y cerrar modo de edición
      this.productosFaltantes = this.productosFaltantes.map((item) => {
        if (item.id === producto.id) {
          return {
            ...item,
            modoEdicion: !item.modoEdicion,
            nuevaCantidad: item.modoEdicion ? item.cantidad : "", // Inicializa con la cantidad actual
          };
        }
        return item;
      });
    },

    cancelarEdicionFaltante(producto) {
      // Restablecer el producto sin cambiar la cantidad y cerrar modo de edición
      this.productosFaltantes = this.productosFaltantes.map((item) => {
        if (item.id === producto.id) {
          return {
            ...item,
            modoEdicion: false,
            nuevaCantidad: "", // Restablece la cantidad a su valor original(item.cantidad)
          };
        }
        return item;
      });
    },

    guardarCantidadModificadaFaltante(producto) {
      if (producto.nuevaCantidad <= 0) {
        alert("La cantidad debe ser mayor a cero.");
        return;
      }

      this.productosFaltantes = this.productosFaltantes.map((item) => {
        if (item.producto_faltante === producto.producto_faltante) {
          return {
            ...item,
            cantidad_faltante: producto.nuevaCantidad,
            modoEdicion: false, // Salir del modo de edición
          };
        }
        return item;
      });

      // Guarda los cambios en Local Storage
      this.guardarFaltantesEnLocalStorage();
      alert("Cantidad modificada con éxito.");
    },

    eliminarFaltante(index) {
      //mostrar un alert de que si quiere eliminar el faltante
      const isConfirmed = window.confirm(
        `¿Esta seguro que desea eliminar el faltante: ${index.producto_faltante}?`
      );
      if (!isConfirmed) {
        return;
      }
      this.productosFaltantes[this.prepedido.id].splice(index, 1);
      this.guardarFaltantesEnLocalStorage();
    },

    //abre el dialogo de modificar del prepedido recien creado desde un evento de AgregarPrepedido
    openDialog(prepedidoId) {
      console.log("ID recibido en ModificarPrepedido:", prepedidoId);
      if (this.prepedido && this.prepedido.id === prepedidoId) {
        console.log("Prepedido encontrado:", this.prepedido);
        console.log("Abriendo el dialogo para el prepedido:", prepedidoId);
        this.dialog = true;
      } else {
        console.log(
          "No se puede abrir el dialogo para el prepedido:",
          prepedidoId
        );
      }
    },
    closeDialog() {
      this.dialog = false;
    },
    cambioDeCliente() {
      console.log(
        "Cliente seleccionado en cambioDeCliente",
        this.clienteSelected
      );
    },
    async getExistenciaProducto(codigoProducto, sucursal) {
      try {
        const response = await axios.get(
          `/productos/consulta?codigo=${codigoProducto}&sucursal=${sucursal}`
        );
        return {
          existencia: response.data.existencia,
          nombre_de_Faltante: response.data.nombre_producto,
        }; //response.data.existencia; // Asume que 'existencia' es el campo que necesitas del response
      } catch (error) {
        console.error(
          `Error al obtener existencia para producto ${codigoProducto}:`,
          error
        );
        return null; // Devuelve null en caso de error
      }
    },

    async getFechasLlegada(codigoProducto, cantidadRequerida) {
      try {
        const response = await axios.get(
          `/envios/llegada_producto?codigo_producto=${codigoProducto}&sucursal=${this.sucursal}`
        );
        let fechasDeLlegada = response.data;

        //si el array es vacio o no es valido
        if (!fechasDeLlegada || fechasDeLlegada.length === 0) {
          console.warn(
            "No hay fechas de llegada para el producto",
            codigoProducto
          );
          this.llegadaProducto = {
            fechasDeLlegada: [],
            fechaProxima: [],
          };
          return;
        }

        fechasDeLlegada.sort((a, b) => {
          const fechaA = new Date(a.fecha_llegada);
          const fechaB = new Date(b.fecha_llegada);
          return fechaA - fechaB;
        });

        /*if (fechasDeLlegada.length !== 0) {
          fechasDeLlegada.sort((a, b) => {
            const fechaA = new Date(a.fecha_llegada);
            const fechaB = new Date(b.fecha_llegada);
            return fechaA - fechaB;
          });*/

        const fechaEncontrada = fechasDeLlegada.find(
          (fecha) => fecha.cantidad >= cantidadRequerida
        );

        let fechaProxima = [];
        if (fechaEncontrada !== undefined) {
          const indice = fechasDeLlegada.indexOf(fechaEncontrada);
          fechaProxima = fechasDeLlegada.splice(indice, 1);
        }

        this.llegadaProducto = {
          fechasDeLlegada: fechasDeLlegada,
          fechaProxima: fechaProxima,
        };
        /*} /*else {
          this.llegadaProducto = {
            fechasDeLlegada: [],
            fechaProxima: [],
          };
        }*/
      } catch (error) {
        console.error("Error al obtener fechas de llegada:", error);
        this.llegadaProducto = {
          fechasDeLlegada: [],
          fechaProxima: [],
        };
      }
    },

    calcularTotal() {
      const total = this.productosDePrepedido.reduce((total, producto) => {
        const subtotal = this.calcularSubtotal(producto);
        return subtotal + total;
      }, 0);

      const totalFormateado = total.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });

      this.$emit("actualizarTotal", this.prepedido.id, totalFormateado);

      return totalFormateado;
    },
    formatoPrecioDescuento(precioProducto, descuento) {
      const numero = parseFloat(precioProducto); // El precio ya tiene el descuento aplicado
      const precio = numero.toFixed(2); // Limitar a 2 decimales

      const separadorDecimal = precio.indexOf(".") !== -1 ? "." : "";
      const [parteEntera, parteDecimal] = precio.split(separadorDecimal);

      // Formatear la parte entera con separador de miles
      const parteEnteraFormateada = parteEntera.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );

      if (descuento > 0) {
        return `${parteEnteraFormateada}${separadorDecimal}${parteDecimal} (-${descuento}%)`;
      } else {
        return `${parteEnteraFormateada}${separadorDecimal}${parteDecimal}`;
      }
    },
    /*async getFechasLlegada(cantidadRequerida) {
      try {
        const response = await axios.get(
          `/envios/llegada_producto?codigo_producto=${this.codigo_producto}&sucursal=${this.sucursal}`
        );
        let fechasDeLlegada = response.data;
        console.log(fechasDeLlegada);

        //si hay envios en camino
        if (fechasDeLlegada.length != 0) {
          fechasDeLlegada.sort((a, b) => {
            // comparar las fechas
            const fechaA = new Date(a.fecha_llegada);
            const fechaB = new Date(b.fecha_llegada);

            // Comparar las fechas
            if (fechaA < fechaB) {
              return -1;
            } else if (fechaA > fechaB) {
              return 1;
            }

            return 0;
          });

          // Obtener el envio proximo que cumpla la cantidad requerida
          const fechaEncontrada = fechasDeLlegada.find(
            (fecha) => fecha.cantidad >= cantidadRequerida
          );

          let fechaProxima = [];

          console.log(fechaEncontrada);
          if (fechaEncontrada !== undefined) {
            const indice = fechasDeLlegada.indexOf(fechaEncontrada);
            fechaProxima = fechasDeLlegada.splice(indice, 1);
          }

          console.log(fechasDeLlegada);
          console.log(fechaProxima);

          // this.llegadaProducto = fechasDeLlegada;

          this.llegadaProducto = {
            fechasDeLlegada: fechasDeLlegada,
            fechaProxima: fechaProxima,
          };

          // fechasDeLlegada.sort((a, b) => {
          //   // comparar las fechas
          //   const fechaA = new Date(a.fecha_llegada);
          //   const fechaB = new Date(b.fecha_llegada);

          //   // Comparar las fechas
          //   if (fechaA < fechaB) {
          //     return -1;
          //   } else if (fechaA > fechaB) {
          //     return 1;
          //   }

          //   return 0;
          // });

          // // Filtrar por cantidad igual o mayor al número recibido
          // fechasDeLlegada = fechasDeLlegada.filter(
          //   (objeto) => objeto.cantidad >= cantidadRequerida
          // );
          // console.log(fechasDeLlegada);
          // this.llegadaProducto = fechasDeLlegada;
        } else {
          this.llegadaProducto = {
            fechasDeLlegada: fechasDeLlegada, //Mandar como array vacio si no tienen algun valor
            fechaProxima: [],
          };
        }
      } catch (error) {
        console.error(error);
      }
    },*/
    async cambioDeVendedor() {
      this.productosDePrepedido = [];
      // Obtener los clientes del usuario
      this.getClientesDelUsuario(this.id_vendedor);
    },
    //Metodo para asignar el vendedor y obtener solo los clientes de la sucursal del vendedor
    asignarVendedor() {
      // console.log(this.entriesClientes);
      const usuarioSelected = this.usuarios.find(
        (usuario) => usuario.id === this.id_vendedor
      );

      // console.log(usuarioSelected);

      // Utilizar el método filter para obtener un nuevo array con clientes cuya sucursal sea la del vendedor
      this.clientesSucursalVendedor = this.entriesClientes.filter(
        (cliente) => cliente.sucursal === usuarioSelected.sucursal
      );
      // console.log(this.clientesSucursalVendedor);
    },

    /*async obtenerProductosPrepedido(idPrepedido) {
      try {
        const response = await axios.get(`/pedidos/get_productos_pre_pedido`, {
          params: { id_producto_pre_pedido: idPrepedido },
        });
        return response.data; // Devuelve la lista de productos
      } catch (error) {
        console.error("Error obteniendo productos del prepedido:", error);
        alert("Hubo un problema al obtener los productos del servidor.");
        return [];
      }
    },*/

    async eliminarProductoTemp(producto) {
      const isConfirmed = window.confirm(
        `¿Esta seguro que desea eliminar el producto ${producto.producto}?`
      );
      if (!isConfirmed) {
        return;
      }

      console.log("Producto a eliminar:", producto);

      // Verificar que el producto tiene un ID
      if (!producto.id) {
        console.error("El producto no tiene un ID definido");
        return;
      }

      const idProducto = producto.id;

      // Paso 1: Obtener los productos del prepedido desde el backend
      try {
        const response = await axios.get(`/pedidos/get_productos_pre_pedido`, {
          params: { id_pre_pedido: this.prepedido.id },
        });
        const productos = response.data;
        console.log("Productos obtenidos del backend:", productos);

        // Encuentra el producto en la lista obtenida del backend
        const productoAEliminar = productos.find(
          (prod) => prod.id === idProducto
        );

        if (productoAEliminar) {
          console.log("Producto a eliminar encontrado:", productoAEliminar);

          // Paso 2: Eliminar el producto de la lista local
          const indiceLocal = this.productosDePrepedido.findIndex(
            (prod) => prod.id === idProducto
          );
          console.log("Índice en la lista local:", indiceLocal);

          if (indiceLocal !== -1) {
            this.productosDePrepedido.splice(indiceLocal, 1);

            // Paso 3: Hacer la solicitud DELETE al backend
            try {
              await axios.delete(`/pedidos/eliminar_productos_pre_pedido`, {
                params: { id_producto_pre_pedido: idProducto },
              });
              console.log("Producto eliminado correctamente del backend");
            } catch (error) {
              console.error(
                "Error al eliminar el producto del backend:",
                error
              );
            }
          } else {
            console.error(
              "Producto no encontrado en la lista local para eliminar"
            );
          }
        } else {
          console.error(
            "Producto no encontrado en la lista obtenida del backend"
          );
        }
      } catch (error) {
        console.error("Error al obtener los productos del prepedido:", error);
      }
    },

    async addProduct() {
      this.isLoading = true;

      if (!this.codigo_producto || this.cantidad_producto <= 0) {
        alert("Los campos de producto y cantidad no pueden estar vacios");
        return;
      }

      //Se obtienen los datos de existencia, precio y descuento del producto
      //para ello se obtiene la sucursal mediante el vendedor
      const vendedorEncontrado = this.usuarios.find(
        (usuario) => usuario.id === this.id_vendedor
      );
      //   let sucursal = "";
      if (vendedorEncontrado != undefined) {
        this.sucursal = vendedorEncontrado.sucursal;
      }

      try {
        //Si no hay existencia entonces se obtienen las fechas de la llegada del producto
        this.llegadaProducto = [];
        if (this.dataProducto.existencia === 0) {
          await this.getFechasLlegada(this.cantidad_producto); //Se llama al metodo y recibe la cantidad agregada por el usuario
        }

        //Calcular el subtotal
        const subtotal =
          this.dataProducto.precio_especial * parseInt(this.cantidad_producto);

        const newProducto = {
          id_pre_pedido: this.prepedido.id,
          codigo_producto: this.codigo_producto ? this.codigo_producto : "",
          nombre: this.dataProducto.nombre_producto
            ? this.dataProducto.nombre_producto
            : "",
          cantidad: this.cantidad_producto ? this.cantidad_producto : "0",
          existencia: this.dataProducto.existencia
            ? this.dataProducto.existencia
            : 0,
          precio: this.dataProducto.precio_especial
            ? this.dataProducto.precio_especial.toFixed(2)
            : "0",
          fechasLlegada: this.llegadaProducto
            ? this.llegadaProducto
            : { fechaProxima: [], fechasDeLlegada: [] },
          sucursal: this.sucursal ? this.sucursal : "",
          subtotal: subtotal ? subtotal : 0,
          //descuento: descuento ? descuento : 0,
          /*subtotalDesc: this.precioDescuento(subtotal, descuento)
            ? this.precioDescuento(subtotal, descuento)
            : "0",*/
          ieps: this.dataProducto.ieps ? this.dataProducto.ieps : 0,
          iva: this.dataProducto.iva ? this.dataProducto.iva : 0,
        };

        this.productosDePrepedido.push(newProducto);
        //await this.addProductToBackend(newProducto);
        /*this.codigo_producto = "";
          this.cantidad_producto = "";*/
        console.log(this.productosDePrepedido);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        alert("Error al agregar producto: " + error.response.data.detail);
        console.error(error);
      }
    },

    //añade el producto al backend para que se alamacene
    async addProductToBackend() {
      this.isLoading = true;

      if (!this.codigo_producto || this.cantidad_producto <= 0) {
        alert("Los campos de producto y cantidad no pueden estar vacios");
        this.isLoading = false;
        return;
      }

      if (!this.prepedido || !this.prepedido.id) {
        console.error("ID de prepedido no disponible");
        return;
      }

      try {
        //implementacion de faltantes
        const existencia = await this.getExistenciaProducto(
          this.codigo_producto,
          this.prepedido.sucursal
        );
        //obtener el nombre del producto

        let cantidadSolicitada = this.cantidad_producto;

        if (cantidadSolicitada > existencia.existencia) {
          const cantidadFaltante = cantidadSolicitada - existencia.existencia;

          if (!this.productosFaltantes[this.prepedido.id]) {
            this.$set(this.productosFaltantes, this.prepedido.id, []);
          }

          this.productosFaltantes[this.prepedido.id].push({
            codigo_cliente: this.codigo_cliente,
            producto_faltante: this.codigo_producto,
            nombre_faltante: existencia.nombre_de_Faltante,
            cantidad_faltante: cantidadFaltante,
            sucursal_faltante: this.prepedido.sucursal,
            id_vendedor: this.id_vendedor,
            observaciones: "Pedido: " + this.prepedido.id,
          });

          this.guardarFaltantesEnLocalStorage();
          cantidadSolicitada = existencia.existencia;
        }
        //fin implementacion faltantes

        // Enviar solicitud al backend para crear el producto
        const response = await axios.post(
          `/pedidos/crear_producto_pre_pedido?id_pre_pedido=${this.prepedido.id}&codigo_producto=${this.codigo_producto}&cantidad=${cantidadSolicitada}`
        );

        // Obtener el producto creado desde la respuesta
        const productoCreado = response.data.objeto; // Asegúrate de ajustar según la estructura real de tu respuesta

        const subtotal = this.calcularSubtotal({
          ...productoCreado,
          cantidad: cantidadSolicitada,
        });
        const precio = this.calcularPrecioAjustado(productoCreado);

        // Agregar el producto creado a la lista local
        this.productosDePrepedido.push({
          id: productoCreado.id,
          producto: productoCreado.codigo_producto,
          nombre: productoCreado.nombre_producto,
          cantidad: cantidadSolicitada,
          iva: productoCreado.iva,
          precio: precio,
          id_pre_pedido: productoCreado.id_pre_pedido,
          ieps: productoCreado.ieps,
          descuento: productoCreado.descuento,
          subtotal: subtotal,
          // Cualquier otro campo que necesites
        });
        /*alert(
          "Producto agregado exitosamente al prepedido: " + this.prepedido.id
        );*/
        await this.loadObjects();
        this.codigo_producto = "";
        this.cantidad_producto = "";
        console.log("Producto agregado a la lista local: ", productoCreado);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        if (
          error.response &&
          error.response.status === 404 &&
          this.codigo_producto.includes("OF")
        ) {
          alert(
            `El producto: ${this.codigo_producto} no existe en la sucursal ${this.sucursal}. Favor de Comunicarse con Yamil`
          );
          this.codigo_producto = "";
          this.cantidad_producto = "";
        }
        console.error("Error al agregar el producto al backend:", error);
      }
    },

    abrirCampoEdicion(producto) {
      this.productosDePrepedido = this.productosDePrepedido.map((item) => {
        if (item.id === producto.id) {
          return {
            ...item,
            modoEdicion: !item.modoEdicion,
            nuevaCantidad: item.modoEdicion ? item.cantidad : "", // Inicializa con la cantidad actual
          };
        }
        return item;
      });
    },

    cancelarEdicion(producto) {
      // Restablecer el producto sin cambiar la cantidad y cerrar modo de edición
      this.productosDePrepedido = this.productosDePrepedido.map((item) => {
        if (item.id === producto.id) {
          return {
            ...item,
            modoEdicion: false,
            nuevaCantidad: "", // Restablece la cantidad a su valor original(item.cantidad)
          };
        }
        return item;
      });
    },

    async guardarCantidadModificada(producto) {
      this.loading = true;
      // Validar que la cantidad sea un número positivo
      if (producto.nuevaCantidad <= 0) {
        alert("La cantidad debe ser mayor que cero.");
        return;
      }

      // Realizar la solicitud PUT al backend
      try {
        await axios.put(
          `/pedidos/modificar_productos_pre_pedido?id_producto_pre_pedido=${producto.id}&nueva_cantidad=${producto.nuevaCantidad}`
        );

        // Actualizar la cantidad en la lista local
        this.productosDePrepedido = this.productosDePrepedido.map((item) => {
          if (item.id === producto.id) {
            const nuevoSubtotal = this.calcularSubtotal({
              ...item,
              cantidad: producto.nuevaCantidad,
            });

            return {
              ...item,
              cantidad: producto.nuevaCantidad,
              subtotal: nuevoSubtotal,
              modoEdicion: false, // Cerrar el modo de edición
            };
          }
          return item;
        });

        alert("Cantidad actualizada con éxito.");
        console.log("Modificada la cantidad del producto:", producto);
      } catch (error) {
        console.error("Error al modificar la cantidad del producto:", error);
        alert("Hubo un error al actualizar la cantidad. Inténtalo de nuevo.");
      }
    },

    //Calcula el subtotal de un producto
    calcularSubtotal(producto) {
      const precioBase = producto.precio;
      const cantidad = producto.cantidad;

      // Convertir porcentajes en valores decimales
      //const descuento = producto.descuento / 100;
      //const ieps = producto.ieps / 100;
      //const iva = producto.iva / 100;

      // Aplicar el descuento al precio base
      //const precioConDescuento = precioBase * (1 - descuento);

      // Aplicar el IEPS al precio después del descuento
      //const precioConIeps = precioConDescuento * (1 + ieps);

      // Aplicar el IVA al precio después del descuento y IEPS
      //const precioFinal = precioConIeps * (1 + iva);

      // Calcular el subtotal multiplicando por la cantidad
      const subtotal = precioBase * cantidad;

      return subtotal;
    },

    //Aplica descuento, IEPS y IVA al precio final mostrado en al lista de un producto
    calcularPrecioAjustado(producto) {
      const precioBase = producto.precio;

      // Convertir porcentajes en valores decimales
      const descuento = producto.descuento / 100;
      const ieps = producto.ieps / 100;
      const iva = producto.iva / 100;

      // Aplicar el descuento al precio base
      const precioConDescuento = precioBase * (1 - descuento);

      // Aplicar el IEPS al precio después del descuento
      const precioConIeps = precioConDescuento * (1 + ieps);

      // Aplicar el IVA al precio después del descuento y IEPS
      const precioFinal = precioConIeps * (1 + iva);

      return precioFinal;
    },

    //carga los datos de los productos del prepedido
    async loadObjects() {
      if (this.prepedido) {
        this.contado = this.prepedido.contado;
        this.prioridad = this.prepedido.prioridad;
        this.observaciones = this.prepedido.observaciones;

        this.localizaciones = Array.isArray(this.prepedido.localizacion)
          ? this.prepedido.localizacion
          : [this.prepedido.localizacion];

        this.VerLocalizaciones = this.prepedido.localizacion || null;

        await this.ObtenerLocalizaciones(this.prepedido.cliente);
      }
      // Asegúrate de tener el ID del prepedido disponible
      if (!this.prepedido || !this.prepedido.id) {
        console.error("ID de prepedido no disponible");
        return;
      }

      const vendedorEncontrado = this.usuarios.find(
        (usuario) => usuario.id === this.id_vendedor
      );
      //   let sucursal = "";
      if (vendedorEncontrado != undefined) {
        this.sucursal = vendedorEncontrado.sucursal;
      }

      try {
        // Hacer una solicitud GET para obtener los productos del prepedido
        const response = await axios.get(
          `/pedidos/get_productos_pre_pedido?id_pre_pedido=${this.prepedido.id}`
        );

        const productos = response.data; // Aquí obtienes la lista de productos desde el backend
        console.log("Productos obtenidos del backend:", productos);

        // Asignar los productos obtenidos a la lista local de productos del prepedido
        this.productosDePrepedido = await Promise.all(
          productos.map(async (producto) => {
            const existencia = await this.getExistenciaProducto(
              producto.codigo_producto,
              this.sucursal
            );
            const subtotal = this.calcularSubtotal(producto);
            const precio = this.calcularPrecioAjustado(producto);

            const productoConDetalles = {
              ...producto,
              producto: producto.codigo_producto,
              nombre: producto.nombre_producto,
              existencia: existencia.existencia,
              precio: precio,
              fechasLlegada: { fechaProxima: [], fechasDeLlegada: [] },
              subtotal: subtotal, // Añadir el subtotal calculado
            };
            if (productoConDetalles.existencia === 0) {
              await this.getFechasLlegada(
                productoConDetalles.codigo_producto,
                productoConDetalles.cantidad
              );
              productoConDetalles.fechasLlegada = this.llegadaProducto;

              //this.getFechasLlegada(producto.cantidad);
            }
            return productoConDetalles;
          })
        );

        this.calcularTotal();
        console.log(
          "Productos con detalles completos cargados en la lista local:",
          this.productosDePrepedido
        );
      } catch (error) {
        console.error("Error al obtener los productos del prepedido:", error);
      }
    },
    validarEntero() {
      this.cantidad_producto = this.cantidad_producto.replace(/\D/g, "");
    },
    cerrar() {
      this.$emit("close");
      this.dialog = !this.dialog;
    },
    async modificarPrepedido() {
      console.log("Cliente seleccionado:", this.clienteSelected);
      console.log("ID del cliente seleccionado:", this.clienteSelected?.id);

      /*if (!this.clienteSelected) {
        alert("Debes de seleccionar un cliente");
        return;
      }*/

      // Aquí enviarías los datos modificados al servidor
      try {
        const datosPrepedido = {
          cliente: this.clienteSelected?.id,
          //id_vendedor: this.id_vendedor,
          prioridad: this.prioridad,
          contado: this.contado,
          observaciones: this.observaciones,
          localizacion: JSON.stringify(this.VerLocalizaciones),
        };

        const response = await axios.put(
          `/pedidos/modificar_pre_pedido?id_pre_pedido=${this.prepedido.id}`,
          datosPrepedido
        );
        console.log("Datos enviados al backend:", datosPrepedido);

        if (response.data && response.data.id_objeto) {
          alert("Prepedido actualizado con éxito.");
          this.$emit("solicitarPrepedidos");
          await this.loadObjects();
          this.dialog = false;
        } else {
          alert("No se pudo actualizar el prepedido.");
        }
      } catch (error) {
        console.error("Error al actualizar el prepedido:", error);
        alert("Ocurrió un error al actualizar el prepedido.");
      }
    },

    /*async obtenerProductosPrepedido(idPrepedido) {
      try {
        const response = await axios.get(`/pedidos/get_productos_pre_pedido`, {
          params: {
            id_pre_pedido: idPrepedido,
          },
        });
        return response.data.productos;
      } catch (error) {
        console.error("Error al obtener productos del prepedido:", error);
        return [];
      }
    },*/
    async getClientesDelUsuario(idUsuario) {
      if (!idUsuario) {
        console.error("ID de usuario no definido");
        return;
      }

      try {
        const response = await axios.get(
          `/clientes/get_mis_clientes?vendedor_id=${idUsuario}`
        );
        this.clientesUsuario = response.data;
      } catch (error) {
        console.error("Error en la solicitud GET", error);
        if (error.response) {
          console.error("Respuesta del servidor:", error.response.data);
          console.error("Código de estado:", error.response.status);
        } else {
          console.error("Error de red o configuración:", error.message);
        }
      }
    },
    getUsuarioAdmin() {
      //Se obtienen los clientes del usuario independientemente de si es admin o no
      //ya que siempre tendremos el id del usuario desde un principio
      //this.getClientesDelUsuario(this.id_vendedor);
      const permisos = this.currentUser.permisos.split(" ");

      // Definir los sufijos buscados
      const sufijosBuscados = [":ADMINISTRADOR"];

      // Comprobar si alguna parte termina con alguno de los sufijos buscados
      const algunaParteTerminaConSufijo = permisos.some((parte) =>
        sufijosBuscados.some((sufijo) => parte.endsWith(sufijo))
      );

      // algunaParteTerminaConSufijo será true si alguna parte termina con "_existencia", de lo contrario, será false
      this.userIsAdmin = algunaParteTerminaConSufijo;

      if (!this.userIsAdmin) {
        this.id_vendedor = this.currentUser.id;
        this.getClientesDelUsuario(this.id_vendedor);
      }
    },

    async ObtenerLocalizaciones(clienteId) {
      try {
        // Realiza la solicitud GET para obtener las localizaciones
        const response = await axios.get(
          `/clientes/get_localizaciones?id_cliente=${clienteId}`
        );
        this.localizaciones = response.data || [];

        if (this.prepedio && this.prepedio.localizacion) {
          this.VerLocalizaciones =
            this.localizaciones.find(
              (loc) => loc.id === this.prepedio.localizacion
            ) || null;
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          alert(
            "No se encontraron localizaciones para este cliente, No puedes modificar el prepedido sin localizacion."
          );
        }
        console.error("Error al obtener localizaciones:", error);
        // En caso de error, limpia localizaciones
        this.localizaciones = [];
        this.VerLocalizaciones = null;
      }
    },

    async enviarFaltantesAlBackend() {
      if (
        !this.productosFaltantes[this.prepedido.id] ||
        this.productosFaltantes[this.prepedido.id].length === 0
      ) {
        alert("No hay productos faltantes para este prepedido.");
        return;
      }
      this.isLoadingFaltanes = true;
      try {
        for (const producto of this.productosFaltantes[this.prepedido.id]) {
          const postData = {
            id_cliente: this.prepedido.cliente,
            sucursal: producto.sucursal_faltante,
            codigo_producto: producto.producto_faltante,
            cantidad: producto.cantidad_faltante,
            id_vendedor: producto.id_vendedor,
            observaciones: producto.observaciones || "",
          };

          await axios.post(`/faltantes/crear`, postData);
          console.log("Productos faltantes enviados al backend:", postData);
        }
        alert("Todos los productos faltantes han sido creados exitosamente.");

        this.$delete(this.productosFaltantes, this.prepedido.id);
        this.guardarFaltantesEnLocalStorage();
      } catch (error) {
        console.error("Error al enviar productos faltantes al backend:", error);
        alert("Ocurrio un error al enviar los productos faltantes.");
      } finally {
        this.isLoadingFaltanes = false;
      }
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
    productos() {
      return this.entries.map((entry) => {
        const codigoDescription =
          entry.codigo.length > this.descriptionLimit
            ? entry.codigo.slice(0, this.descriptionLimit) + "..."
            : entry.codigo;
        const nombreDescription =
          entry.nombre.length > this.descriptionLimit
            ? entry.nombre.slice(0, this.descriptionLimit) + "..."
            : entry.nombre;
        return Object.assign({}, entry, {
          Description: `${codigoDescription} - ${nombreDescription}`,
        });
      });
    },
    clientes() {
      return this.clientesUsuario.map((entry) => {
        const codigoDescription =
          entry.codigo.length > this.descriptionLimit
            ? entry.codigo.slice(0, this.descriptionLimit) + "..."
            : entry.codigo;
        // const rfcDescription =
        //   entry.rfc.length > this.descriptionLimit
        //     ? entry.rfc.slice(0, this.descriptionLimit) + "..."
        //     : entry.rfc;
        const nombreDescription =
          entry.razon_social.length > this.descriptionLimit
            ? entry.razon_social.slice(0, this.descriptionLimit) + "..."
            : entry.razon_social;
        return Object.assign({}, entry, {
          Description: `${codigoDescription} - ${nombreDescription}`,
        });
      });
    },
    getFilteredUsuarios() {
      return this.usuarios.filter((user) => {
        return user.nombre_adminpaq !== null;
      });
    },
  },
  mounted() {
    // Cargar objetos desde el almacenamiento local al iniciar el componente
    this.getUsuarioAdmin();
    this.loadObjects();
    this.cargarFaltantesDeLocalStorage();
  },
  created() {
    //cargar clientes del usuario
    //this.loadObjects();
    //this.getCliente();
    //this.dialog = false;
    this.$root.$on("prepedidoCreado", this.openDialog);
  },
  /*beforeDestroy() {
    this.$root.$off("prepedidoCreado", this.openDialog);
  },*/
  watch: {
    prepedido: {
      handler(newValue) {
        this.clienteSelected = newValue.cliente || null;
      },
      deep: true,
    },

    clienteSelected(newValue) {
      if (newValue) {
        this.ObtenerLocalizaciones(newValue.id);
      } else {
        this.localizaciones = [];
        this.VerLocalizaciones = null;
      }
    },
  },
};
</script>

<style scoped>
.close-btn {
  float: right;
}

.color-tabla {
  background-color: rgba(230, 230, 95, 0.3);
}
</style>
