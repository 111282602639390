<template>
  <v-container v-if="user">
    <v-card>
      <v-card-title class="text-center teal lighten-3 accent-4 white--text">
        Corte
      </v-card-title>
      <v-row class="mx-2 mt-1">
        <v-col cols="12" lg="3" md="3">
          <v-select
            v-model="sucursalSelected"
            :items="sucursales"
            item-value="value"
            label="Sucursal"
            placeholder="Selecciona sucursal"
          ></v-select>
        </v-col>
        <!-- Selector de fecha completa -->
        <v-col cols="12" lg="3" md="3">
          <v-menu
            ref="menuFecha"
            v-model="menuFecha"
            :close-on-content-click="false"
            :return-value.sync="selectedDate"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formattedDate"
                label="Fecha"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="selectedDate"
              @change="onDateSelected"
              locale="es"
              @input="menuFecha = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2">
          <v-btn @click="fetchData" small class="mt-5" :loading="loading"
            >Buscar</v-btn
          >
        </v-col>
      </v-row>
      <!-- Tabla de resultados -->
      <p class="text-center" v-if="tableData.length">
        <b>Fecha:</b> {{ formattedDate }} - <b>Total:</b>
        {{ sumTotal }}
      </p>
      <v-data-table
        v-if="tableData.length"
        :headers="headers"
        :items="tableData"
        class="elevation-1 ma-2"
        :footer-props="{
          'items-per-page-text': 'Elementos por página',
        }"
        :header-props="{
          'sort-by-text': 'Ordenar por',
        }"
      >
        <template v-slot:[`item.total`]="{ item }">
          {{ "$" + item.total }}
        </template>
      </v-data-table>
      <v-btn
        v-if="tableData.length"
        class="ma-3"
        color="primary"
        @click="downloadExcel"
      >
        Descargar Excel
      </v-btn>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
//import * as XLSX from "xlsx";

export default {
  data() {
    return {
      loading: false,
      selectedDate: new Date().toISOString().slice(0, 10),
      formattedDate: new Date().toISOString().slice(0, 10),
      menuFecha: false,
      tableData: [],
      sucursalSelected: this.currentUser?.sucursal || "Mexicali",
      sucursales: [
        {
          text: "Mexicali",
          value: "Mexicali",
        },
        {
          text: "Tijuana",
          value: "Tijuana",
        },
        {
          text: "Ensenada",
          value: "Ensenada",
        },
        {
          text: "La Paz",
          value: "LaPaz",
        },
        {
          text: "Obregón",
          value: "Obregon",
        },
        {
          text: "Hermosillo",
          value: "Hermosillo",
        },
      ],
      headers: [
        {
          text: "Serie y Folio",
          value: "serie_folio",
        },
        {
          text: "Codigo cliente",
          value: "codigo_cliente",
        },
        {
          text: "Rfc",
          value: "rfc",
        },
        {
          text: "Razón social",
          value: "razon_social",
          // sortable: false,
        },
        {
          text: "Concepto",
          value: "concepto",
        },
        {
          text: "Total",
          value: "total",
        },
      ],
    };
  },
  methods: {
    CalculateSumTotal() {
      const sum = this.tableData.reduce((acc, item) => acc + item.total, 0);
      return sum.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });
    },

    onDateSelected() {
      const date = new Date(this.selectedDate);
      this.formattedDate = date.toISOString().split("T")[0];
      this.$refs.menuFecha.save(this.selectedDate);
      this.menuFecha = false;
    },

    async fetchData() {
      this.loading = true;
      try {
        const response = await axios.get(`/reportes/get_corte`, {
          params: {
            fecha: this.selectedDate,
            sucursal: this.sucursalSelected,
          },
        });

        const { facturas } = response.data;
        this.tableData = facturas.map((factura) => ({
          serie_folio: `${factura.serie} - ${factura.folio}`,
          codigo_cliente: factura.codigo_cliente,
          rfc: factura.rfc,
          razon_social: factura.razon_social,
          concepto: factura.concepto,
          total: factura.total,
        }));
      } catch (error) {
        if (error.response.status === 404) {
          alert("No hay facturas para la fecha seleccionada.");
          this.loading = false;
          return;
        }
        console.error("Error al obtener los datos:", error);
      }
      this.loading = false;
    },

    async downloadExcel() {
      try {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Corte Factura");

        const currentDate = new Date().toLocaleString();

        // Encabezados personalizados con estilos en negritas
        worksheet.addRow(["Corte Factura"]).font = { bold: true };
        worksheet.addRow([`Fecha: ${this.formattedDate}`]).font = {
          bold: true,
        };
        worksheet.addRow([`Sucursal: ${this.sucursalSelected}`]).font = {
          bold: true,
        };
        worksheet.addRow([`Total: ${this.sumTotal}`]).font = { bold: true };
        worksheet.addRow([`Fecha de impresión: ${currentDate}`]).font = {
          bold: true,
        };

        // Fila en blanco
        worksheet.addRow([]);

        // Encabezados de la tabla
        const headers = [
          "Serie - Folio",
          "Codigo Cliente",
          "RFC",
          "Razón Social",
          "Concepto",
          "Total",
        ];
        const headerRow = worksheet.addRow(headers);

        // Aplicar negritas a los encabezados de la tabla
        headerRow.font = { bold: true };

        // Agregar los datos de la tabla
        this.tableData.forEach((factura) => {
          worksheet.addRow([
            factura.serie_folio,
            factura.codigo_cliente,
            factura.rfc,
            factura.razon_social,
            factura.concepto,
            `$ ${factura.total}`,
          ]);
        });

        // Ajustar el ancho de las columnas automáticamente
        worksheet.columns.forEach((column) => {
          column.width = column.header ? column.header.length + 5 : 20;
        });

        // Generar y guardar el archivo Excel
        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), `Corte_${this.formattedDate}.xlsx`);
      } catch (error) {
        console.error("Error al generar el archivo Excel:", error);
      }
    },
  },
  created() {},
  mounted() {},
  watch: {},
  components: {},
  computed: {
    ...mapGetters(["user", "currentUser"]),
    sumTotal() {
      return this.CalculateSumTotal();
    },
  },
};
</script>
