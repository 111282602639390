<template>
  <v-container v-if="user">
    <v-card>
      <v-card-title class="text-center teal lighten-3 accent-4 white--text">
        Visitas
      </v-card-title>
      <form ref="form" @submit.prevent="getVisitas()">
        <v-row class="mx-2 mt-1">
          <v-col cols="6" md="4">
            <v-autocomplete
              label="Vendedor"
              v-model="vendedorSelected"
              :items="itemsUsuarios"
              item-text="nombre"
              item-value="id"
              required
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" md="3">
            <v-text-field
              class="ml-3"
              label="Semana"
              v-model="currentWeek"
              type="number"
              required
              :hint="calcularSemana(currentWeek)"
              @input="validarEntero"
            />
          </v-col>
          <v-col cols="6" md="3">
            <v-text-field
              class="ml-3"
              label="Año"
              v-model="currentYear"
              type="number"
              required
            />
          </v-col>
          <v-col cols="6" md="2">
            <v-btn type="submit" :loading="loadingBtn" class="mt-4"
              >Enviar
            </v-btn>
          </v-col>
        </v-row>
      </form>
    </v-card>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-card-title class="text-center teal lighten-3 accent-4 white--text">
          Visitas
          <v-spacer></v-spacer>
          <v-btn icon dark @click="cerrarDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-btn
            v-if="!mostrarAnterior"
            class="my-3"
            color="primary"
            :loading="loadingAnterior"
            @click="semanaAnterior"
            >Semana {{ currentWeek - 1 }}</v-btn
          >
          <p v-if="!mostrarAnterior">{{ calcularSemana(currentWeek - 1) }}</p>
          <VisitasComponent
            class="my-5"
            v-if="mostrarAnterior"
            :items="itemsAnterior"
            :semana="parseInt(currentWeek) - 1"
            :rangoSemana="calcularSemana(currentWeek - 1)"
            :vendedorNombre="getVendedorNombre()"
          ></VisitasComponent>

          <!-- v-if mostrarActual para que se actualice el Doughnut con los datos de una posible segunda consulta -->
          <VisitasComponent
            v-if="mostrarActual"
            :items="items"
            :semana="currentWeek.toString()"
            :rangoSemana="calcularSemana(currentWeek)"
            :vendedorNombre="getVendedorNombre()"
          ></VisitasComponent>

          <v-btn
            v-if="!mostrarSiguiente"
            class="my-3"
            color="primary"
            :loading="loadingSiguiente"
            @click="semanaSiguiente"
            >Semana {{ parseInt(currentWeek) + 1 }}</v-btn
          >
          <p v-if="!mostrarSiguiente">
            {{ calcularSemana(parseInt(currentWeek) + 1) }}
          </p>
          <VisitasComponent
            class="my-5"
            v-if="mostrarSiguiente"
            :items="itemsSiguiente"
            :semana="parseInt(currentWeek) + 1"
            :rangoSemana="calcularSemana(parseInt(currentWeek) + 1)"
            :vendedorNombre="getVendedorNombre()"
          ></VisitasComponent>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import VisitasComponent from "@/components/VisitasComponent.vue";
import { getISOWeek, startOfWeek, endOfWeek, format, parseISO } from "date-fns";
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      items: [],
      itemsAnterior: [],
      itemsSiguiente: [],

      itemsUsuarios: [],
      semana: "",
      dialog: false,
      vendedorSelected: "",

      loadingBtn: false,
      loadingAnterior: false,
      loadingSiguiente: false,

      mostrarAnterior: false,
      mostrarActual: false,
      mostrarSiguiente: false,

      currentWeek: null,
      currentYear: new Date().getFullYear(),
    };
  },
  methods: {
    async semanaSiguiente() {
      this.loadingSiguiente = true;
      axios
        .get(
          `/gps/get_visitas_semana?id_vendedor=${
            this.vendedorSelected
          }&numero_semana=${parseInt(this.currentWeek) + 1}&year=${
            this.currentYear
          }`
        )
        .then((response) => {
          // Aquí puedes manejar la respuesta de la API
          this.itemsSiguiente = response.data;
          // console.log(this.itemsSiguiente);
          this.loadingSiguiente = false;
          this.mostrarSiguiente = true;
        })
        .catch((error) => {
          // Aquí puedes manejar los errores
          console.error(error);
          this.loadingSiguiente = false;
          alert("Error: " + error.response.data.detail);
        });
    },
    async semanaAnterior() {
      // console.log();
      this.loadingAnterior = true;
      axios
        .get(
          `/gps/get_visitas_semana?id_vendedor=${
            this.vendedorSelected
          }&numero_semana=${this.currentWeek - 1}&year=${this.currentYear}`
        )
        .then((response) => {
          // Aquí puedes manejar la respuesta de la API
          this.itemsAnterior = response.data;
          // console.log(this.itemsAnterior);
          this.loadingAnterior = false;
          this.mostrarAnterior = true;
        })
        .catch((error) => {
          if (error.response.status === 400) {
            alert(
              "Numero de semana invalido. Verifique el rango de semanas desde 1 a 52."
            );
          }
          // Aquí puedes manejar los errores
          console.error(error);
          this.loadingAnterior = false;
          //alert("Error: " + error.response.data.detail);
        });
    },
    async getVisitas() {
      this.loadingBtn = true;
      // console.log(this.currentWeek);
      // console.log(this.currentWeek.toString());
      axios
        .get(
          `/gps/get_visitas_semana?id_vendedor=${this.vendedorSelected}&numero_semana=${this.currentWeek}&year=${this.currentYear}`
        )
        .then((response) => {
          // Aquí puedes manejar la respuesta de la API
          this.items = response.data;
          console.log(this.items);
          this.loadingBtn = false;
          this.dialog = true;
          this.mostrarActual = true;
        })
        .catch((error) => {
          // Aquí puedes manejar los errores
          console.error(error);
          this.loadingBtn = false;
          alert("Error: " + error.response.data.detail);
        });
    },
    async getUsuarios() {
      try {
        const response = await axios.get("/usuarios/get_usuarios");
        this.itemsUsuarios = response.data.usuarios.filter(
          (usuario) => usuario.nombre_adminpaq
        );
      } catch (error) {
        console.error(error);
      }
    },

    getVendedorNombre() {
      const vendedor = this.itemsUsuarios.find(
        (usuario) => usuario.id === this.vendedorSelected
      );
      return vendedor ? vendedor.nombre : "Vendedor no encontrado";
    },

    mostrarDialog() {
      this.dialog = true;
      console.log(this.calcularSemana(this.currentWeek));
    },
    cerrarDialog() {
      this.dialog = false;
      this.mostrarAnterior = false;
      this.mostrarSiguiente = false;
      this.mostrarActual = false;
      this.items = [];
      this.itemsSiguiente = [];
      this.itemsAnterior = [];
    },
    calcularSemana(numeroSemana) {
      // Usar el año seleccionado por el usuario
      const startDateISO = startOfWeek(parseISO(`${this.currentYear}-01-01`), {
        weekStartsOn: 1,
      });
      const startDate = new Date(startDateISO);
      startDate.setDate(startDate.getDate() + (numeroSemana - 1) * 7);

      const endDateISO = endOfWeek(parseISO(`${this.currentYear}-01-01`), {
        weekStartsOn: 1,
      });
      const endDate = new Date(endDateISO);
      endDate.setDate(endDate.getDate() + (numeroSemana - 1) * 7);

      const formatoInicio = format(startDate, "dd/MM/yyyy");
      const formatoFin = format(endDate, "dd/MM/yyyy");

      return `Desde ${formatoInicio} a ${formatoFin}`;
      /*
      // Calcular la fecha de inicio de la semana específica
      const currentYear = new Date().getFullYear();
      const startDateISO = startOfWeek(parseISO(`${currentYear}-01-01`), {
        weekStartsOn: 1,
      }); // Empieza la semana el lunes
      const startDate = new Date(startDateISO);
      startDate.setDate(startDate.getDate() + numeroSemana * 7);

      // Calcular la fecha de fin de la semana específica
      const endDateISO = endOfWeek(parseISO(`${currentYear}-01-01`), {
        weekStartsOn: 1,
      }); // Termina la semana el domingo
      const endDate = new Date(endDateISO);
      endDate.setDate(endDate.getDate() + numeroSemana * 7); // Resta 1 porque la semana actual ya cuenta como la primera

      // Formatear las fechas en el formato deseado (dd/mm/aaaa)
      const formatoInicio = format(startDate, "dd/MM/yyyy");
      const formatoFin = format(endDate, "dd/MM/yyyy");

      return `Desde ${formatoInicio} a ${formatoFin}`;
      */
    },
    validarEntero() {
      this.currentWeek = this.currentWeek.replace(/\D/g, "");
    },
  },
  created() {
    this.getUsuarios();
    // Obtener la fecha actual
    const currentDate = new Date();

    // Obtener el número de la semana actual
    this.currentWeek = getISOWeek(currentDate);
    // console.log(this.currentWeek);
  },
  components: { VisitasComponent },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>
